import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./animate.css";
import "./index.css";
import "./admin.css";
import Loader from "./Loader";
import User from "./routes/user";
import Landingpage from "./routes/landingpage";
import Superadmin from "./routes/superadmin";
import Doc from "./routes/doc";
import Donor from "./routes/donor";
import { getCurrentOrg } from "./redux/actions/profileActions";
import { connect } from "react-redux";
import MainToast from "./components/toast/MainToast";
import Toast from "./components/toast";
import { Toaster } from "react-hot-toast";

import headingContext from "./utils/headingContext";

const App = ({ getCurrentOrg, PData }) => {
  const [Heading, setHeading] = useState(null);
  let value = { Heading, setHeading };
  const [Org, setOrg] = useState("");

  let token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      getCurrentOrg();

    }
  }, [getCurrentOrg, token]);

  useEffect(() => {
    if (PData) {
      setOrg(PData);
    }
  }, [PData]);

  const refreshOrg = async () => {
    await getCurrentOrg();
  };



  return (
    <headingContext.Provider value={value}>
      <Suspense fallback={<Loader />}>
        <MainToast />
        <Toaster />
        <Toast />
        <Router>
          {Org && Org.user_type === "org" && (
            <User org={Org} refreshOrg={refreshOrg} />
          )}

          {Org && Org.user_type === "superadmin" && (
            <Superadmin org={Org} refreshOrg={refreshOrg} />
          )}
          {Org && Org.user_type === "donor" && (
            <Donor org={Org} refreshOrg={refreshOrg} />
          )}



          <Landingpage setOrg={(e) => setOrg(e)} org={Org} />
          <Doc setOrg={(e) => setOrg(e)} org={Org} />
        </Router>
      </Suspense>
    </headingContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    PData: state.profile.list,
  };
};

export default connect(mapStateToProps, { getCurrentOrg })(App);
