import { projectOneConstants } from '../constants';
const {
    PGReq,
    PGSuc,
    PGErr,
    PGAddAtt,
    PDReq,
    PDSuc,
    PDErr,
    PFReq,
    PFSuc,
    PFErr,
    PAReq,
    PASuc,
    PAErr,
    PRReq,
    PRSuc,
    PRErr,
    PUReq,
    PUSuc,
    PUErr,
    PUClr,
    PUDelAtt,
    PUAddAtt,
    PIReq,
    PISuc,
    PIErr,
    POReq,
    POSuc,
    POErr,
    POClr,
    PODelAtt,
    POAddAtt,
}= projectOneConstants;

const iSD = {
    isSuc: false, isErr: false, isL: false,list:[]
};
const iSU = {
    isSuc: false, isErr: false, isL: false,list:[]
};
const iSG = {
    isSuc: false, isErr: false, isL: false,list:[]
};
const iSR = {
    isSuc: false, isErr: false, isL: false,list:[]
};
const iSA = {
    isSuc: false, isErr: false, isL: false,list:[]
};
const iSF = {
    isSuc: false, isErr: false, isL: false,list:[]
};
const iSI = {
    isSuc: false, isErr: false, isL: false,list:[]
};

const iSO = {
    isSuc: false, isErr: false, isL: false,list:[]
};
export const ProjectOneD = (state = iSD, action) => {
    switch (action.type) {
        case PDReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case PDSuc: return { ...state, isErr: false, isSuc: true, isL: false ,data:action.payload};
        case PDErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}


export const ProjectOneG = (state = iSG, action) => {
    let listTemp=state.list
    switch (action.type) {
        case PGReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case PGSuc: return { ...state, isErr: false, isSuc: true, isL: false ,list:action.payload};
        case PGErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case PGAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };
        default: return state;
    }
}

export const ProjectOneR = (state = iSR, action) => {


    switch (action.type) {


        case PRSuc: return { ...state, isErr: false, isSuc: true, isL: false ,results:action.payload};
        case PRReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case PRErr: return { ...state, isErr: true, isSuc: false, isL: false };
      
        default: return state;
    }
}

export const ProjectOneA = (state = iSA, action) => {


    switch (action.type) {
   
        case PAReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case PASuc: return { ...state, isErr: false, isSuc: true, isL: false ,activities:action.payload};
        case PAErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}
export const ProjectOneU = (state = iSU, action) => {

let listTemp=state.list
    switch (action.type) {
    
        
        case PUReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case PUSuc: return { ...state, isErr: false, isSuc: true, isL: false ,list:action.payload};
        case PUErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case PUClr: return { ...state, isErr: false, isSuc: false, isL: false };

        case PUDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => i._id !== action.payload._id);
            }

            return {
                ...state, list: listTemp, isL: false
            };

       

        case PUAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}


export const ProjectOneF = (state = iSF, action) => {


    switch (action.type) {
         case PFReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case PFSuc: return { ...state, isErr: false, isSuc: true, isL: false ,files:action.payload};
        case PFErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}

export const ProjectOneI = (state = iSI, action) => {


    switch (action.type) {
         case PIReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case PISuc: return { ...state, isErr: false, isSuc: true, isL: false ,list:action.payload};
        case PIErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}



export const ProjectOneO = (state = iSO, action) => {

    let listTemp=state.list
        switch (action.type) {
        
            
            case POReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
            case POSuc: return { ...state, isErr: false, isSuc: true, isL: false ,list:action.payload};
            case POErr: return { ...state, isErr: true, isSuc: false, isL: false };
            case POClr: return { ...state, isErr: false, isSuc: false, isL: false };
    
            case PODelAtt:
                if (listTemp && listTemp.length > 0) {
                    listTemp = listTemp.filter(i => i._id !== action.payload._id);
                }
    
                return {
                    ...state, list: listTemp, isL: false
                };
    
           
    
            case POAddAtt:
                if (listTemp && listTemp.length > 0)
                    listTemp = listTemp.concat([action.payload]);
                else if (listTemp) {
                    listTemp = [].concat([action.payload]);
                }
    
                return {
                    ...state, list: listTemp, isL: false
                };
    
            default: return state;
        }
    }