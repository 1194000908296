
import api from '../../utils/api';
import axios from 'axios';

export const registerFileNormal = async (data, file) => {
    try {
        let res = await api.post("/sdg_docs/register", data);
        if (res.data.url && !res.data.error) {
            uploadFile(res.data.url, file)
        }

    } catch (error) {
        console.log("error to upload", error)
    }
};






export const uploadFile = async (url, file) => {
    try {
        await axios.put(url, file, {
            onUploadProgress: function (progressEvent) {
                let percentCheck = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log("percentCheck", percentCheck)
            }
        });

    } catch (error) {
        console.log("error to upload", error)
    }
}


export const getAllByType = async (data) => {
    try {
        let res = await api.get("/sdg_docs/getAllByType", { params: data });
        if (res.data.files && !res.data.error) {
            return res.data.files
        }

    } catch (error) {
        return null
    }
};








