import { organizationConstants,profileConstants } from "../constants";
import api from '../../utils/api';
import Token from './token';
import { ModalProcess } from './profileActions';


// import { logOut } from "./userActions";
const {  GOIErr, GOIReq, GOISuc, POIErr, POIReq, POISuc,AOErr, AOReq, AOSuc, } = organizationConstants;
//GPISuc,GAddAtt, GPBill


const { GPClr } = profileConstants;
export const getBasicPackagesList = async() => {
    try {
        let res = await api.get("/packages/getBasic");
        if (res.data && !res.data.error) {
            return res.data
        } else{
            console.log("res.data.error",res.data.error)
        }
    } 
    catch(error){
        console.log("error",error)
    }
}

export const resetPassword = data => async dispatch => {
    try {
        let res = await api.post("/organization/reset", data);
        if (res.data && !res.data.error) {
            dispatch(ModalProcess({text:res.data } ));
            dispatch({ type: GOISuc, payload: res.data.organization });
        } else dispatch(ModalProcess({ title: 'error', text: res.data.error, isErr: true }));
    } 
    catch{
        dispatch(ModalProcess({ title: 'organization', text: 'Your session has expired. Please login again.', isErr: true  }));
    }
}

export const newPassword = data => async dispatch => {
    try {
        let res = await api.post("/organization/updatePassword", data);
        if (res.data && !res.data.error) {
            dispatch(ModalProcess({text:res.data } ));
            dispatch({ type: GOISuc, payload: res.data.organization });
        } else dispatch(ModalProcess({ title: 'error', text: res.data.error, isErr: true }));
    } 
    catch{
        dispatch(ModalProcess({ title: 'organization', text: 'Your session has expired. Please login again.', isErr: true  }));
    }
}

export const getAllOrgforweb = (data) => async dispatch => {
    try {
        dispatch({ type: AOReq });    
         Token();
        let res = await api.get(`/organization/getAllOrg`,{params:data, headers: { 'authorization': `${localStorage.getItem('token')}` }});
    
        if (!res.data.error && res.data) {
            dispatch({ type: AOSuc, payload: res.data });
        } else dispatch({ type: AOErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}

export const getAllOrg = () => async dispatch => {
    try {
        dispatch({ type: GOIReq });    
         Token();
        let res = await api.get(`/organization/getAll`);
    
        if (!res.data.error && res.data) {
            dispatch({ type: GOISuc, payload: res.data });
        } else dispatch({ type: GOIErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}

export const getAllPrivateOrg = () => async dispatch => {
    try {
        dispatch({ type: POIReq });    
        Token();
        let res = await api.get(`/organization/getAllPrivate`,{headers: { 'authorization': `${localStorage.getItem('token')}` }});
    
        if (!res.data.error && res.data) {
            dispatch({ type: POISuc, payload: res.data });
        } else dispatch({ type: POIErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}
export const getAllPublicOrg = () => async dispatch => {
    try {
        dispatch({ type: GOIReq });    
        // Token();
        let res = await api.get(`/organization/getAllPublic`,{headers: { 'authorization': `${localStorage.getItem('token')}` }});
    
        if (!res.data.error && res.data) {
            dispatch({ type: GOISuc, payload: res.data });
        } else dispatch({ type: GOIErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}

export const getAllDonorOrg = () => async dispatch => {
    try {
        dispatch({ type: GOIReq });    
        // Token();
        let res = await api.get(`/organization/getAllDonor`,{headers: { 'authorization': `${localStorage.getItem('token')}` }});
    
        if (!res.data.error && res.data) {
            dispatch({ type: GOISuc, payload: res.data });
        } else dispatch({ type: GOIErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}
export const getOrgData = () => async dispatch => {
    try {
        dispatch({ type: GOIReq });    
         Token();
        let res = await api.get(`/organization/getOrgData`,{headers: { 'authorization': `${localStorage.getItem('token')}` }});
  
        if (!res.data.error && res.data) {
            dispatch({ type: GOISuc, payload: res.data });
        } else dispatch({ type: GOIErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}
export const getOrgList = () => async dispatch => {
    try {
        dispatch({ type: GOIReq });    
         Token();
        let res = await api.get(`/organization/getAllList`,{headers: { 'authorization': `${localStorage.getItem('token')}` }});
    
        if (!res.data.error && res.data.organization) {
            dispatch({ type: GOISuc, payload: res.data.organization });
        } else dispatch({ type: GOIErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}

export const getEmail = () => async dispatch => {
    try {
        dispatch({ type: GOIReq });    
        // Token();
        let res = await api.get(`/organization/getEmail`);
    
        if (!res.data.error && res.data.organization) {
            dispatch({ type: GOISuc, payload: res.data.organization });
        } else dispatch({ type: GOIErr });
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}
export const logOut = () =>  dispatch =>{
    
    localStorage.removeItem("token");
    dispatch(window.location.href ='/login')
    dispatch({ type: GPClr ,payload:null})
    dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true, }));

};

// export const getCurrentOrg = () => dispatch => {
//     !localStorage.getItem('token') ? dispatch({ type: CUErr }) : dispatch({ type: CUSuc });
//     dispatch(getProfile());
// };



