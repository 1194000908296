import { projActivityConstants } from '../constants';
const { P_ActAddAtt, P_ActClr, P_ActDelAtt,GP_ActSuc,GPI_ActSuc,GPA_ActSuc, P_ActErr, P_ActReq, P_ActSuc, P_ActUptAtt } = projActivityConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const projActReducer = (state = iS, action) => {

    let listTemp = state.list;
    switch (action.type) {
        case P_ActSuc: return { ...state, isErr: false, isSuc: true, isL: false,  };
        case P_ActReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case P_ActErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GPA_ActSuc: return { ...state, dataList: action.payload };
        case GPI_ActSuc: return { ...state, data: action.payload };
        case GP_ActSuc: return { ...state, list: action.payload };
        case P_ActClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case P_ActDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case P_ActUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case P_ActAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
