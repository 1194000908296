import { modalConstants, profileConstants, notificationConstants, } from "../constants";
import api from '../../utils/api';
import Token from './token';
import axios from 'axios';

 import { logOut } from "./organizationActions";
const { GPErr,GPReq,GPSuc, SideNav, GPReqS, SideType,PErr,PReq,PSuc} = profileConstants;
const { MHide, MShow, NTMHide, NTMShow } = modalConstants;
const { NReq, NSuc, GSSuc } = notificationConstants;



export const DispatchAppType = (type) => async dispatch => dispatch({ type: SideType, payload: type });

export const ModalHide = () => async dispatch => dispatch({ type: MHide });

export const ModalNTHide = () => async dispatch => dispatch({ type: NTMHide });

export const ModalProcess = data => dispatch => {
    dispatch({ type: MShow, payload: data });
    if(data&&data.progress){
        if(data.progress===100){
            setTimeout(function () {
                dispatch({ type: MHide });
            }, 3000);
        }
 
}else{
    setTimeout(function () {
        dispatch({ type: MHide });
    }, 5000);
}
}

export const ModalProcessNT = data => dispatch => {
    dispatch({ type: NTMShow, payload: data });
    // if(data&&data.progress===100){
        setTimeout(function () {
            dispatch({ type: NTMHide });
        }, 3000);
    
   
}

export const getAllSearchList=()=>async dispatch=>{
    try {
        dispatch({type:NReq})
                let res = await api.get(`/organization/getAllSearchList`, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
        if (!res.data.error) {
            // console.log("callinf",res.data)
            let list={files:res.data.files,project:res.data.project}
            dispatch({ type: GSSuc, payload: list });
            dispatch({ type: NSuc });

        }
    } catch  {
                // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    
    }

}


export const getAllSearchListForAdmin=()=>async dispatch=>{
    try {
        dispatch({type:NReq})
                let res = await api.get(`/organization/getAllSearchListForAdmin`, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
        if (!res.data.error) {
            // console.log("callinf",res.data)
            let list={files:res.data.files,project:res.data.project}
            dispatch({ type: GSSuc, payload: list });
            dispatch({ type: NSuc });

        }
    } catch  {
                // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    
    }

}

export const getProfile=()=>async dispatch=>{
    try {
        dispatch({type:PReq})
                let res = await api.get(`/profile/fetchProfile`, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
        if (!res.data.error) {
            dispatch({ type: PSuc, payload: res.data });

        }
        else dispatch({ type: PErr });
    } catch  {
                // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    
    }

}

export const updateProfile=(file,data)=>async dispatch=>{
    try {
                let res = await api.post(`/profile/updateProfile`, data,{ headers: { 'authorization': `${localStorage.getItem('token')}` } });
        if (!res.data.error) {
           dispatch(ModalProcess({ title: 'User', text: 'Profile details has been updated.' }));

            // dispatch({ type: PSuc, payload: res.data.profile });

        }
    } catch  {
                // dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    
    }

}
export const uploadImage = (data,file) => async dispatch => {
    try {
        dispatch({ type: GPReqS });
        Token();
        var res = await api.post(`/profile/update_img`,data, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
        if ( res.data.url && !res.data.error) {
            dispatch(uploadFile(file,res.data.url));

            // dispatch(ModalProcess({ title: 'User', text: 'Profile details has been updated.' }));

        } else dispatch({ type: GPErr });
    } catch {
        dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
};


export const uploadFile = (file, url,tit) => async dispatch => {
    try {
 
        let percentCheck = 0;

        await axios.put(url, file, {
            onUploadProgress: function (progressEvent) {
                percentCheck = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(ModalProcess({progress:percentCheck}))
            }
        });
        if(percentCheck===100){
            dispatch(getCurrentOrg())
            // dispatch(ModalProcess({ text: tit }));

        }
     
    } catch {
        // dispatch(logOut());
        dispatch(ModalProcess({text: 'Error to upload file ' }));
    }
}
export const getCurrentOrg=()=>async dispatch=>{
    try {
        dispatch({type:GPReq,payload:null})
                let res = await api.get(`/organization/getCurrentOrg`, { headers: { 'authorization': `${localStorage.getItem('token')}` } });
        if (!res.data.error&&res.data) {
            // console.log("callinf",data)
            if(res.data.profile&&res.data.package){
                let data={...res.data.profile,...res.data.package}
               
                dispatch({ type: GPSuc, payload: data });

            }else{
                dispatch({ type: GPSuc, payload: res.data.profile });

            }

        }else{
             console.log("callinf",res.data)
        }
    } catch  {
        //dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    
    }

}

export const setSN = num => async dispatch => {
    dispatch({ type: SideNav, payload: num });
};

