import { trainingAdvisoryConstants } from '../constants';
const {
    TranAdAddAtt,
    TranAdClr,
    TranAdErr,
    TranAdReq,
    TranAdSuc,
    TranAdList,
    TranAdData,
    TranAdDelAtt,
    TranAdUptAtt,
} = trainingAdvisoryConstants
const iS = {
    isSuc: false, isErr: false, isL: false, list: [], data: null
};

export const TrainingAdvisoryReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case TranAdSuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case TranAdList: return { ...state, list: action.payload };
        case TranAdData: return { ...state, data: action.payload };
        case TranAdReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TranAdErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TranAdClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TranAdDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => (i._id) !== (action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TranAdUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if ((i._id) === (action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TranAdAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
