import { targetConstants } from '../constants';
const { TAddAtt, TClr, TDelAtt, TErr, TReq, TSuc, TUptAtt } = targetConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: [],new:[]
};

export const TargetReducer = (state = iS, action) => {

    let listTemp = state.list;
    let listAdd = state.new;

    switch (action.type) {
        case TSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case TReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TDelAtt:
            if (listAdd && listAdd.length > 0) {
                listAdd = listAdd.filter(i => i.id !== action.payload._id);
            }

            return {
                ...state, new: listAdd, isL: false
            };

        case TUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TAddAtt:
            if (listAdd && listAdd.length > 0)
            listAdd = listAdd.concat([action.payload]);
            else if (listAdd) {
                listAdd = [].concat([action.payload]);
            }

            return {
                ...state, new: listAdd, isL: false
            };

        default: return state;
    }
}
