import React, { useState, useEffect ,useContext} from 'react';
import '../index.css'
import NotificationIcon from '../../assets/Top nav notification.svg'
import Setting from '../../assets/Top nav settings.svg'
import Userdami from '../../assets/topavatar.svg'
import JointIcon from '../../assets/Side Nav joint vneture.svg'
import ProjectsIcon from '../../assets/Side nav projects.svg'
import FilesIcon from '../../assets/Sode nav all files.svg'
import ViewIcon from '../../../assets/sdg/view_profile.svg'
import LogoutIcon from '../../../assets/sdg/logout.svg'
import { connect } from 'react-redux';
import { getAllSearchList } from '../../../redux/actions/profileActions';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from '../../../redux/actions/organizationActions';
import headingContext from '../../../utils/headingContext';
import {AiOutlineHome} from 'react-icons/ai'
const Topbar = ({ logOut, getAllSearchList, data, PData ,path}) => {
    const [query, setSearchFun] = useState(''),[IPClos, setIPClos] = useState(false), [Project, setProject] = useState([]), [files, setfiles] = useState([]), [Org, setOrg] = useState('');
    const navigate = useNavigate()
    const {Heading} = useContext(headingContext);

    useEffect(() => {
        if (data && data.project) { setProject(data.project) }
        if (data && data.files) { setfiles(data.files) }
    }, [data])

    useEffect(() => {
        if (PData ) { setOrg(PData) }
    }, [PData])

    let con = []

    con = con.concat(files, Project)
    let finder = con.filter(post => {
        if (query === '') {
            return null;
        } else if (post.name.toLowerCase().includes(query.toLowerCase())) {
            return post;
        }
        return null
    })

    useEffect(() => {
        getAllSearchList()
    }, [getAllSearchList])

    let date = new Date()
    const month = date.toLocaleString('default', { month: 'short' });
    const y = date.getFullYear()
    const nth = function (d) {
        const dString = String(d);
        const last = +dString.slice(-2);
        if (last > 3 && last < 21) return `${last}th`;
        switch (last % 10) {
            case 1: return `${last}st`;
            case 2: return `${last}nd`;
            case 3: return `${last}rd`;
            default: return `${last}th`;
        }
    }
    return <div className='row m-0 p-0 ' >

        <div className='col-md-3 ps-0 p-1 m-0'>
            {Heading?<>
                <h5 className='p-0 m-0 text-capitalize'><strong>{Heading}</strong></h5>
            </>:<>
            
            {(!path||path === 'dashboard') && <h5 className='p-0 m-0'><strong>Dashboard</strong></h5>}

            {path === 'notifications' && <>
                <h5 className='p-0 m-0'><strong>Notifications</strong></h5>
            </>}
            {path === 'settings' && <h5 className='p-0 m-0'><strong>Settings</strong></h5>}
            {path === 'profile' && <h5 className='p-0 m-0'><strong>Profile</strong></h5>}

            {path === 'all_files' && <h5 className='p-0 m-0'><strong>All Files</strong></h5>}
            {path === 'projects' && <h5 className='p-0 m-0'><strong>Projects</strong></h5>}
            {path === 'joint_ventures' && <h5 className='p-0 m-0'><strong>Joints Ventures</strong></h5>}
            {path === 'org_sdg_tracker' && <h5 className='p-0 m-0'><strong>SDG Tracker</strong></h5>}
            {path === 'employees' && <h5 className='p-0 m-0'><strong>Employees</strong></h5>}</>}
            
       

            <small className='p-0 m-0 ' style={{ color: "#c4c4c4" }}>{nth(date.getDate())} {month}, {y}</small>
        </div>
        <div className='col-md-1'></div>

        <div className="  col-md-6  d-flex" style={{ width: "50%" }}>
            <div className="input-group mb-3 d-flex ">
                <input type="text" className="form-control searchbar border-0 " style={{
                    backgroundColor: "#F6F6F6", borderRadius: `${finder.length > 0&&IPClos ? "20px 20px 0px 0px" : "20px"}`,
                    borderBottomRightRadius: "20px", height: "38px", padding: " 0px 0px 0px 46px"
                }} placeholder="Search " value={query} onChange={(event) =>{
                    setIPClos(true)
                    setSearchFun(event.target.value)}} aria-label="Username" aria-describedby="basic-addon1" />

            </div>
            {finder.length > 0 &&IPClos&& <div className='shadow-sm' style={{ position: "absolute", overflow: "auto", zIndex: "1", width: "34.5%", maxHeight: "78%", top: "53px", }}>
                <div className='list-group border-0'>
                    {finder && finder.map(e => <>
                        <Link to={
                            e.proj_type === 'joints' ? `/organization/joint_ventures/view_joints_project/${e._id}`
                                : e.proj_type === 'individual' ? `/organization/projects/view_project/${e._id}`
                                    : e.activity && e.project && e.project.proj_type === 'joints' ? `/organization/joint_ventures/view_joints_activity/${e.activity._id}`
                                        : e.activity && e.project && e.project.proj_type === 'individual' ? `/organization/projects/view_activity/${e.activity._id}`
                                            : e.project && !e.activity && e.project.proj_type === 'joints' ? `/organization/joint_ventures/view_joints_project/${e.project._id}`
                                                : e.project && !e.activity && e.project.proj_type === 'individual' ? `/organization/projects/view_project/${e.project._id}`
                                                    : ""} className="list-group-item border-0 border-bottom list-group-item-action" onClick={()=>{
                                                        setSearchFun('')
                                                        setIPClos(false)}}>
                            <div className='d-flex '>
                                <img src={`${e.proj_type === 'joints' ? JointIcon : e.proj_type === 'individual' ? ProjectsIcon : e.type ? FilesIcon : ""}`} alt="" style={{ width: `${e.type ? "12px" : "16px"}` }} />
                                <p className='p-0 my-0 mx-2  '>
                                    <p className='p-0 m-0' style={{ fontSize: "15px" }}>{e.name}</p>
                                    <p className='p-0 m-0' style={{ fontSize: "11px", lineHeight: "5px", color: "gray" }}>
                                        {e.proj_type === 'joints' && 'Joints Venture'}

                                        {e.proj_type === 'individual' && 'Project'}
                                        <span className='text-capitalize'>{e.project && e.project.name}</span>
                                    </p>
                                </p>
                            </div>
                        </Link>
                    </>)}
                </div>

            </div>}
        </div>
        <div className='col-md-2 d-flex' style={{ padding: "0px 0px 0px 40px" }} >
            <Link to="/organization/settings">
                <img src={Setting} className="mx-2" style={{ width: "25px", }} alt="setting" />
            </Link>
            <Link to="/organization/notifications">
                <img src={NotificationIcon} className="mx-2" style={{ width: "22px" }} alt="notification" />

            </Link>


            <div className="dropdown">
                <img src={Org && Org.logo ? Org.logo : Userdami} className="mx-2 border rounded-circle dropdown-toggle" style={{ width: "28px", height: "28px", cursor: "pointer" }} alt="user" id="fdropdownMenuButton1" data-bs-toggle="dropdown"  />

                <ul className="dropdown-menu" aria-labelledby="fdropdownMenuButton1">
                
                <li><Link className="dropdown-item" to="/" style={{ fontSize: "14px" }}><AiOutlineHome  className="me-2"  style={{ fontSize: "17px" }} />Landing Page</Link></li>

                    <li><Link className="dropdown-item" to="/organization/profile" style={{ fontSize: "14px" }}><img src={ViewIcon} className="me-2" alt="ViewIcon" style={{ width: "17px" }} />View Profile</Link></li>
                    <li className="dropdown-item" onClick={() => {
                        logOut()
                        localStorage.removeItem('token')
                        navigate('/login')
                   //  window.location.href = '/login'
                    }} style={{ cursor: "pointer", fontSize: "14px" }}><img src={LogoutIcon} className="me-2" alt="ViewIcon" style={{ width: "17px" }} />Logout</li>
                </ul>
            </div>

        </div>




    </div>
};
const mapStateToProps = state => {

    return {
        data: state.Notification.Sdata,
        PData: state.profile.list

    }
}

export default connect(mapStateToProps, {logOut, getAllSearchList })(Topbar);
