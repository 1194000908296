import { notificationConstants } from "../constants";
const { CNValue,CNTValue, NErr, GNSuc, NReq, NSuc,NLSuc, NLTSuc ,GSSuc,NTReq,NTSuc,NTErr} = notificationConstants;
const iS = { isL: false, isErr: false, isSuc: false };

export const NotifReducer = (state = iS, action) => {
    switch (action.type) {
        case NReq: return { ...state, isL: true, isErr: false, isSuc: false };
        case NTReq: return { ...state, isL: true, isErr: false, isSuc: false };

        case NErr: return { ...state, isL: false, isErr: true, isSuc: false };
        case NTErr: return { ...state, isL: false, isErr: true, isSuc: false };

        case NSuc: return {...state, isL: false, isErr: false, isSuc: true};
        case NTSuc: return {...state, isL: false, isErr: false, isSuc: true};

        case GNSuc: return { ...state, data: action.payload };
        case GSSuc: return { ...state, Sdata: action.payload };

        case NLSuc: return { ...state, list: action.payload }
        case NLTSuc: return { ...state, listT: action.payload }

        default: return state;
    }
}

export const countReducer = (state = { count: 0 }, action) => {
    switch (action.type) {
        case CNValue: return { ...state, count: action.payload };
        case CNTValue: return { ...state, countT: action.payload };

        default: return state;
    }
}