import React from 'react'
import SDGIcon from '../assets/sdglogo.svg'
import DashboardIcon from '../assets/Side nav dashboard.svg'
import EmployeesIcon from '../assets/Side nav employees.svg'
import JointIcon from '../assets/Side Nav joint vneture.svg'
import FilesIcon from '../assets/Sode nav all files.svg'
//import Logout from '../../assets/admin/logout.svg'
// import {fetchNotification} from '../../redux/actions/notificationActions'
import { connect } from 'react-redux'
import ProjectsIcon from '../assets/Side nav projects.svg'
import { Link } from 'react-router-dom'

import './sidenav.css'
const SideNav = ({ pId, goalId, JA, DB, prof, path }) => {





  return (

    <div className='text-dark  py-4  ' style={{ backgroundColor: "#dfdfdf", borderRadius: "10px", height: " 95vh", width: "100%" }}>
      <div className='d-flex TeamableTop justify-content-center'>
        <img src={SDGIcon} alt="" style={{ width: "190px" }} />

      </div>
      <div className=" sidenavul border-0  " style={{ position: "relative", top: "10px", padding: "61px 0px" }}>
        <ul>
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${!path || path === "dashboard" || DB ? "setActivebg" : ""}`} to="/organization"><img src={DashboardIcon} alt="dashboard" style={{ width: "18px" }} /><p className='FiloSubMenu ' style={{ marginLeft: "3px" }}>Dashboard</p> </Link>
          </li>

          <li>
            <Link className={`d-flex text-dark text-decoration-none ${path === "projects" ? "setActivebg" : ""}`} to="/organization/projects"><img src={ProjectsIcon} alt="projects" style={{ width: "15px" }} /><p className='FiloSubMenu mx-2'>Projects</p> </Link>
          </li>
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${path === "employees" && "setActivebg"}`} to="/organization/employees"><img src={EmployeesIcon} alt="" style={{ width: "16px" }} /><p className='FiloSubMenu mx-2'>Employees</p> </Link>
          </li>
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${path === "joint_ventures" ? "setActivebg" : JA && "setActivebg"}`} to="/organization/joint_ventures"><img src={JointIcon} alt="" style={{ width: "18px" }} /><p className='FiloSubMenu'>Joint Ventures</p> </Link>
          </li>
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${path === "all_files" && "setActivebg"}`} to="/organization/all_files"><img src={FilesIcon} alt="" style={{ width: "13px" }} /><p className='FiloSubMenu mx-2'>All Files</p> </Link>
          </li>

        </ul>

      </div>

    </div>

  )
}
const mapStateToProps = (state) => {
  return {
    prof: state.profile.list
  }
}
export default connect(mapStateToProps)(SideNav)