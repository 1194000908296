import { trainingCategoriesConstants } from '../constants';
const { TranCAddAtt, TranCClr, TranCDelAtt, TranCErr, TranCReq, TranCSuc, TranCUptAtt } = trainingCategoriesConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const TrainingCategoriesReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case TranCSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case TranCReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TranCErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TranCClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TranCDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => (i._id) !== (action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TranCUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if ((i._id) === (action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TranCAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
