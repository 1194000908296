import { fileConstants } from '../constants';

const {
    FClr, FErr, FReq, FSuc, FUpt, GURLSuc, GFSuc, GFISuc, FCountS, CTCountS,
    ErrReplace, ErrRClr, FDelAtt, FUptAtt, FAddAtt, AddToF, DelToF,
    ReplaceFileVer, FUptNew, FUptNewClr
} = fileConstants;

const iS = {
    isSuc: false, isErr: false, isL: false,
    isUpt: false, countS: 0, countFS: 0
};

export const FileReducer = (state = iS, action) => {

    let listTemp = state.list;
    let data = state.data;

    switch (action.type) {
        case FUpt:
            let newArr = action.payloadExtra;
            let per = action.payload;

            if (action.isM && action.tp === 'Add') {
                per = state.per && Array.isArray(state.per) ? [...state.per] : [];
                per = per.concat([action.payload]);
            } else if (action.isM && action.tp === 'Upt') {
                per = state.per && Array.isArray(state.per) ? [...state.per] : [];
                per[action.i] = action.payload;
            }

            return {
                ...state,
                isErr: false,
                isSuc: false,
                isL: true,
                isUpt: true,
                per: per,
                fileData: newArr,
                isM: action.isM,
                percent: action.percent
            };


        case FUptNew:
            let fileList = state.fileData ? [...state.fileData] : [];
            let files = action.payloadExtra;


            if (action.add) fileList = files.concat(fileList);
            else {
                fileList.map((i) => {
                    files.map((j) => {
                        if (i._id === j._id || i.tempId === j.tempId) {
                            i = j;
                        }
                        return j;
                    });

                    return i;
                });
            };

            return {
                ...state,
                isUpt: true,
                fileData: fileList
            };


        case FUptNewClr: return { ...state, fileData: [] };
        case FSuc: return { ...state, isErr: false, isSuc: true, isL: false, isUpt: false };
        case FReq: return { ...state, isErr: false, isSuc: false, isL: true, isUpt: false };
        case FErr: return { ...state, isErr: true, isSuc: false, isL: false, isUpt: false };
        case FClr: return { ...state, isErr: false, isSuc: false, isL: false, isUpt: false, isM: false };
        case GFISuc: return { ...state, data: action.payload };
        case GURLSuc: return { ...state, url: action.payload };
        case GFSuc: return { ...state, list: action.payload };
        case FCountS: return { ...state, countS: action.payload };
        case CTCountS: return { ...state, countFS: action.payload };
        case ErrReplace: return { ...state, combinedFile: action.payload, isL: false };
        case ErrRClr: return { ...state, combinedFile: null };

        case AddToF:
            if (data) {
                data.isF = true;
            }
            return { 
                ...state, data: data };

        case DelToF:
            if (data) {
                data.isF = false;
            }
            return { ...state, data: data };

        case FDelAtt:
            if (listTemp && listTemp.files && listTemp.files.length > 0) {
                if (action.payload.arr && action.payload.arr.length > 0) {
                    listTemp.files = listTemp.files.filter(i => !action.payload.arr.includes(Number(i._id)));
                } else {
                    listTemp.files = listTemp.files.filter(i => Number(i._id) !== Number(action.payload._id));
                }
            } else if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            // if (listTemp && listTemp.catList && listTemp.catList.length > 0)
            //     listTemp.catList = listTemp.catList.filter(i => Number(i._id) !== Number(action.payload._id));

            // if (listTemp && listTemp.cats && listTemp.cats.length > 0)
            //     listTemp.cats = listTemp.cats.filter(i => Number(i._id) !== Number(action.payload._id));

            return {
                ...state, list: listTemp, isL: false
            };

        case FUptAtt:
            if (listTemp && listTemp.files && listTemp.files.length > 0)
                listTemp.files = listTemp.files.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            if (listTemp && listTemp.catList && listTemp.catList.length > 0)
                listTemp.catList = listTemp.catList.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });


            if (listTemp && listTemp.cats && listTemp.cats.length > 0)
                listTemp.cats = listTemp.cats.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });
            return {
                ...state, list: listTemp, isL: false
            };

        case ReplaceFileVer:
            if (listTemp && listTemp.files && listTemp.files.length > 0)
                listTemp.files = listTemp.files.map(i => {
                    if (Number(i._id) === Number(action.payload.repId)) i = action.payload;
                    return i;
                });

            else if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload.repId)) i = action.payload;
                    return i;
                });
            }

            return {
                ...state, list: listTemp, isL: false
            };


        case FAddAtt:
            if (listTemp && listTemp.files && listTemp.files.length > 0 && action.payload.rType === 0)
                listTemp.files = listTemp.files.concat([action.payload]);
            else if (listTemp && action.payload.rType === 0) {
                listTemp.files = [].concat([action.payload]);
            }

            if (listTemp && listTemp.catList && listTemp.catList.length > 0 && action.payload.rType === 1)
                listTemp.catList = listTemp.catList.concat([action.payload]);
            else if (listTemp && action.payload.rType === 1) {
                listTemp.catList = [].concat([action.payload]);
            }

            if (listTemp && listTemp.cats && listTemp.cats.length > 0 && action.payload.rType === 1) {
                listTemp.cats = listTemp.cats.concat([action.payload]);
            } else if (listTemp && action.payload.rType === 1) {
                listTemp.cats = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false, isUpt: false
            };
        default: return state;
    }
}
