import { districtConstants } from '../constants';
const { DISAddAtt, DISClr, DISDelAtt, DISErr, DISReq, DISSuc, DISUptAtt } = districtConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const DistrictReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case DISSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case DISReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case DISErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case DISClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case DISDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case DISUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case DISAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
