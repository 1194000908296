import { organizationConstants } from '../constants';
const { GOIErr, GOIReq, GOISuc, GPISuc, GPBill,GPSSuc,POIErr, POIReq, POISuc, AOErr, AOReq, AOSuc,} = organizationConstants;


const iS = { isSuc: false, isErr: false, isL: false, bill: 0 };

export const OrganizationReducer = (state = iS, action) => {

    switch (action.type) {
        case GOIReq: return { ...state, isL: true, isErr: false, isSuc: false }
        case GOISuc: return { ...state, isL: false, isErr: false, isSuc: true, data: action.payload }
        case GOIErr: return { ...state, isL: false, isErr: true, isSuc: false }
        case POIReq: return { ...state, isL: true, isErr: false, isSuc: false }
        case POISuc: return { ...state, isL: false, isErr: false, isSuc: true, p_data: action.payload }
        case POIErr: return { ...state, isL: false, isErr: true, isSuc: false }

        case AOReq: return { ...state, isL: true, isErr: false, isSuc: false }
        case AOSuc: return { ...state, isL: false, isErr: false, isSuc: true, data2: action.payload }
        case AOErr: return { ...state, isL: false, isErr: true, isSuc: false }
        case GPISuc: return { ...state, packages: action.payload }
        case GPSSuc: return { ...state, data: action.payload }

        case GPBill: return {...state, bill: action.payload };
        default: return state;
    }
};



