import { notificationConstants } from "../constants";
import api from '../../utils/api';
// import history from '../../utils/history';
import Token from './token';
import { ModalProcess } from "./profileActions";
import { logOut } from "./organizationActions";
const { CNValue, NErr, NReq, NSuc, NLSuc } = notificationConstants;
//CNTValue,NTErr,NTReq,NTSuc,NLTSuc,GNSuc
const notifList = { data: [], count: 0 };

export const fetchNotification = () => async dispatch => {
    try {
        dispatch({ type: NReq });
        // Token();
        const res1 = await api.get("/notifications/list", {headers: { 'authorization': `${localStorage.getItem('token')}` } });
        if (res1.data.notifs && res1.data.notifCount !== null && !res1.data.error) {
            notifList.data = res1.data.notifs;
            notifList.count = res1.data.notifCount
            dispatch({ type: CNValue, payload: res1.data.count });
            dispatch({ type: NLSuc, payload: notifList });
            dispatch({ type: NSuc });
        } else dispatch({ type: NErr });
    } catch {
        dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
};



export const deleteAllNotification = () => async dispatch => {
    try {
        dispatch({ type: NReq });
        Token();
        const res1 = await api.post(`/notifications/deleteAll`, '',{headers: { 'authorization': `${localStorage.getItem('token')}` }} );
        dispatch(ModalProcess({ text: 'All Notification has been deleted.' }));
        if (res1.data.notifs && res1.data.notifCount !== null && !res1.data.error) {
            notifList.data = res1.data.notifs;
            notifList.count = res1.data.notifCount
            dispatch({ type: CNValue, payload: res1.data.count });
            dispatch({ type: NLSuc, payload: notifList });
            dispatch({ type: NSuc });
        } else dispatch({ type: NErr });
    } catch {
        dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}

export const readAllNotification = () => async dispatch => {
    try {
        dispatch({ type: NReq });
        Token();
        const res1 = await api.post(`/notifications/readAll`,'',{headers: { 'authorization': `${localStorage.getItem('token')}` }});
        dispatch(ModalProcess({  text: 'All Notification has been marked as read.' }));
        if (res1.data.notifs && res1.data.notifCount !== null && !res1.data.error) {
            notifList.data = res1.data.notifs;
            notifList.count = res1.data.notifCount
            dispatch({ type: CNValue, payload: res1.data.count });
            dispatch({ type: NLSuc, payload: notifList });
            dispatch({ type: NSuc });
        } else dispatch({ type: NErr });
    } catch {
        dispatch(logOut());
        dispatch(ModalProcess({ title: 'Session', text: 'Your session has expired. Please login again.', isErr: true }));
    }
}