export const loginConstants = {
    LReq: 'L_REQ',
    LErr: 'L_ERR',
    LSuc: 'L_SUC'
};

export const authConstants = {
    SReq: 'S_REQ',
    SErr: 'S_ERR',
    SSuc: 'S_SUC'
};
export const inneruserConstants = {
    UReq: 'U_REQ',
    USuc: 'U_SUC',
    UErr: 'U_ERR',
    UClr: 'U_CLR',
    UDelAtt: 'U_DEL_ATT',
    UUptAtt: 'U_UPT_ATT',
    UAddAtt: 'U_ADD_ATT'
};
export const profileConstants = {
    PReq: 'P_REQ',
    PSuc: 'P_SUC',
    PErr: 'P_ERR',
    GPReq: 'GP_REQ',
    GPReqS: 'GPS_REQ',
    GPSuc: 'GP_SUC',
    GPErr: 'GP_ERR',
    GPClr: 'GP_Clr',
    CUSuc: 'CU_SUC',
    CUErr: 'CU_ERR',
    GDReq: 'GD_REQ',
    GDSuc: 'GD_SUC',
    GDErr: 'GD_ERR',
    GDESuc: 'GDE_SUC',
    SideNav: 'SN_VAL',
    SideType: 'SN_TYPE',
};
export const organizationConstants = {
    GOIReq: 'GOI_REQ',
    GOISuc: 'GOI_SUC',
    GOIErr: 'GOI_ERR',
    POIReq: 'POI_REQ',
    POISuc: 'POI_SUC',
    POIErr: 'POI_ERR',
    GPISuc: 'GPI_SUC',
    GPSSuc: 'GPS_S_SUC',
    AOReq: 'A_O_REQ',
    AOSuc: 'A_O_SUC',
    AOErr: 'A_O_ERR',
    GPBill: 'GP_BILL',
    GAddAtt: 'G_ADD_ATT',


};
export const donorConstants = {
    DReq: 'DONAR_REQ',
    DSuc: 'DONAR_SUC',
    DErr: 'DONAR_ERR',
    DClr: 'DONAR_CLR',
    DDelAtt: 'DONAR_DEL_ATT',
    DUptAtt: 'DONAR_UPT_ATT',
    DAddAtt: 'DONAR_ADD_ATT'
};
export const modalConstants = {
    MShow: 'M_SHOW',
    MHide: 'M_HIDE',
    NTMShow: 'NTM_SHOW',
    NTMHide: 'NTM_HIDE'
};

export const goalConstants = {
    GReq: 'G_REQ',
    GSuc: 'G_SUC',
    GCSuc: 'GC_SUC',
    GNSuc: 'GN_SUC',

    GErr: 'G_ERR',

    GOReq: 'G_O_REQ',
    GOSuc: 'G_O_SUC',
    GOErr: 'G_O_ERR',
    GPReq: 'G_P_REQ',
    GPSuc: 'G_P_SUC',
    GPErr: 'G_P_ERR',
    GTOReq: 'G_TO_REQ',
    GTOSuc: 'G_TO_SUC',
    GTOErr: 'G_TO_ERR',
    GLReq: 'G_L_REQ',
    GLSuc: 'G_L_SUC',
    GLErr: 'G_L_ERR',
    GClr: 'G_CLR',
    GDelAtt: 'G_DEL_ATT',
    GUptAtt: 'G_UPT_ATT',
    GAddAtt: 'G_ADD_ATT'
};
export const targetConstants = {
    TReq: 'T_REQ',
    TSuc: 'T_SUC',
    TErr: 'T_ERR',
    TClr: 'T_CLR',
    TDelAtt: 'T_DEL_ATT',
    TUptAtt: 'T_UPT_ATT',
    TAddAtt: 'T_ADD_ATT'
};
export const indicatorConstants = {
    IReq: 'I_REQ',
    ISuc: 'I_SUC',
    IErr: 'I_ERR',
    IWGReq: 'I_W_G_REQ',
    IWGSuc: 'I_W_G_SUC',
    IWGErr: 'I_W_G_ERR',
    IClr: 'I_CLR',
    GISuc: 'G_I_SUC',
    IDelAtt: 'I_DEL_ATT',
    IUptAtt: 'I_UPT_ATT',
    IAddAtt: 'I_ADD_ATT'
};

export const provinceConstants = {
    ProvReq: 'Prov_REQ',
    ProvSuc: 'Prov_SUC',
    ProvErr: 'Prov_ERR',
    ProvClr: 'Prov_CLR',
    ProvDelAtt: 'Prov_DEL_ATT',
    ProvUptAtt: 'Prov_UPT_ATT',
    ProvAddAtt: 'Prov_ADD_ATT'
};

export const divisionConstants = {
    DIVReq: 'DIV_REQ',
    DIVSuc: 'DIV_SUC',
    DIVErr: 'DIV_ERR',
    DIVClr: 'DIV_CLR',
    DIVDelAtt: 'DIV_DEL_ATT',
    DIVUptAtt: 'DIV_UPT_ATT',
    DIVAddAtt: 'DIV_ADD_ATT'
};
export const districtConstants = {
    DISReq: 'DIS_REQ',
    DISSuc: 'DIS_SUC',
    DISErr: 'DIS_ERR',
    DISClr: 'DIS_CLR',
    DISDelAtt: 'DIS_DEL_ATT',
    DISUptAtt: 'DIS_UPT_ATT',
    DISAddAtt: 'DIS_ADD_ATT'
};
export const tehsilConstants = {
    TehReq: 'Teh_REQ',
    TehSuc: 'Teh_SUC',
    TehErr: 'Teh_ERR',
    TehClr: 'Teh_CLR',
    TehDelAtt: 'Teh_DEL_ATT',
    TehUptAtt: 'Teh_UPT_ATT',
    TehAddAtt: 'Teh_ADD_ATT'
};
export const uCConstants = {
    UcReq: 'Uc_REQ',
    UcSuc: 'Uc_SUC',
    UcErr: 'Uc_ERR',
    UcClr: 'Uc_CLR',
    UcDelAtt: 'Uc_DEL_ATT',
    UcUptAtt: 'Uc_UPT_ATT',
    UcAddAtt: 'Uc_ADD_ATT'
};
export const cityConstants = {
    CTReq: 'CT_REQ',
    CTSuc: 'CT_SUC',
    CDReq: 'CD_REQ',
    CDSuc: 'CD_SUC',
    CDErr: 'CD_ERR',
    CTErr: 'CT_ERR',
    CTClr: 'CT_CLR',
    CTDelAtt: 'CT_DEL_ATT',
    CTUptAtt: 'CT_UPT_ATT',
    CTAddAtt: 'CT_ADD_ATT'
};

export const projectConstants = {
    PROJReq: 'PROJ_REQ',
    PROJSuc: 'PROJ_SUC',
    PROJErr: 'PROJ_ERR',
    PWReq: 'PROJ_W_REQ',
    PWSuc: 'PROJ_W_SUC',
    PWErr: 'PROJ_W_ERR',
    GPReq: 'G_P_REQ',
    GPSuc: 'G_P_SUC',
    GPErr: 'G_P_ERR',
    PROJClr: 'PROJ_CLR',
    GPROJSuc: 'G_PROJ_SUC',
    PROJDelAtt: 'PROJ_DEL_ATT',
    PROJUptAtt: 'PROJ_UPT_ATT',
    PROJAddAtt: 'PROJ_ADD_ATT'
};

export const projectOneConstants = {
    PGReq: 'P_G_REQ',
    PGSuc: 'P_G_SUC',
    PGErr: 'P_G_ERR',
    PGAddAtt: 'P_G_UPT_ATT',

    PDReq: 'P_D_REQ',
    PDSuc: 'P_D_SUC',
    PDErr: 'P_D_ERR',
    PFReq: 'P_F_REQ',
    PFSuc: 'P_F_SUC',
    PFErr: 'P_F_ERR',
    PAReq: 'P_A_REQ',
    PASuc: 'P_A_SUC',
    PAErr: 'P_A_ERR',

    PRReq: 'P_R_REQ',
    PRSuc: 'P_R_SUC',
    PRErr: 'P_R_ERR',

    PUReq: 'P_U_REQ',
    PUSuc: 'P_U_SUC',
    PUErr: 'P_U_ERR',
    PUClr: 'P_U_Clr',
    PUDelAtt: 'P_U_DEL_ATT',
    PUAddAtt: 'P_U_UPT_ATT',

    PIReq: 'P_I_REQ',
    PISuc: 'P_I_SUC',
    PIErr: 'P_I_ERR',

    POReq: 'P_O_REQ',
    POSuc: 'P_O_SUC',
    POErr: 'P_O_ERR',
    POClr: 'P_O_Clr',
    PODelAtt: 'P_O_DEL_ATT',
    POAddAtt: 'P_O_UPT_ATT',
};




export const projUserConstants = {
    P_U_Req: 'P_U_REQ',
    P_U_Suc: 'P_U_SUC',
    P_U_Err: 'P_U_ERR',
    P_U_Clr: 'P_U_CLR',
    P_U_DelAtt: 'P_U_DEL_ATT',
    P_U_UptAtt: 'P_U_UPT_ATT',
    P_U_AddAtt: 'P_U_ADD_ATT'
};
export const employeeConstants = {
    EmpReq: 'Emp_REQ',
    EmpSuc: 'Emp_SUC',
    EmpErr: 'Emp_ERR',
    EmpClr: 'Emp_CLR',
    EmpDelAtt: 'Emp_DEL_ATT',
    EmpUptAtt: 'Emp_UPT_ATT',
    EmpAddAtt: 'Emp_ADD_ATT'
};


export const fileConstants = {
    FReq: 'F_REQ',
    FSuc: 'F_SUC',
    FErr: 'F_ERR',
    FClr: 'F_CLR',
    FUpt: 'F_UPT',
    FUptNew: 'F_UPT_NEW',
    FUptNewClr: 'F_UPT_NEW_CLR',
    FAddAtt: 'F_ADD_ATT',
    FDelAtt: 'F_DEL_ATT',
    FUptAtt: 'F_UPT_ATT',
    GFSuc: 'GF_SUC',
    GFISuc: 'GFI_SUC',
    GURLSuc: 'GURL_SUC',
    CTCountS: 'CT_COUNT_S',
    FCountS: 'F_COUNT_S',
    ErrReplace: 'ERR_REPLACE',
    ErrRClr: 'ERR_REPLACE_CLR',
    AddToF: 'ADD_TO_F',
    DelToF: 'DEL_TO_F',
    ReplaceFileVer: 'REP_FILE_VER'
};

export const activityOneConstants = {


    ADReq: 'A_D_REQ',
    ADSuc: 'A_D_SUC',
    ADErr: 'A_D_ERR',
    AFReq: 'A_F_REQ',
    AFSuc: 'A_F_SUC',
    AFErr: 'A_F_ERR',
    ARReq: 'A_R_REQ',
    ARSuc: 'A_R_SUC',
    ARErr: 'A_R_ERR',
    AUReq: 'A_U_REQ',
    AUSuc: 'A_U_SUC',
    AUErr: 'A_U_ERR',
    AUClr: 'A_U_Clr',
    AUDelAtt: 'A_U_DEL_ATT',
    AUAddAtt: 'A_U_UPT_ATT',








};
export const projActivityConstants = {
    P_ActReq: 'P_Act_REQ',
    P_ActSuc: 'P_Act_SUC',
    P_ActErr: 'P_Act_ERR',
    P_ActClr: 'P_Act_CLR',
    GPI_ActSuc: 'GPI_Act_SUC',
    GPA_ActSuc: 'GPA_Act_SUC',
    GP_ActSuc: 'GP_Act_SUC',
    P_ActDelAtt: 'P_Act_DEL_ATT',
    P_ActUptAtt: 'P_Act_UPT_ATT',
    P_ActAddAtt: 'P_Act_ADD_ATT'
};

export const jointVenturesConstants = {
    JVReq: 'JV_REQ',
    JVSuc: 'JV_SUC',
    JVErr: 'JV_ERR',
    JVClr: 'JV_CLR',
    JWReq: 'JV_W_REQ',
    JWSuc: 'JV_W_SUC',
    JWErr: 'JV_W_ERR',
    GJVSuc: 'G_JV_SUC',
    JVDelAtt: 'JV_DEL_ATT',
    JVUptAtt: 'JV_UPT_ATT',
    JVAddAtt: 'JV_ADD_ATT'
};




export const notificationConstants = {
    CNValue: 'N_VAL',
    CNTValue: 'N_T_VAL',

    NReq: 'N_REQ',
    NTReq: 'N_T_REQ',

    NErr: 'N_ERR',
    NTErr: 'N_T_ERR',

    NSuc: 'N_SUC',
    NTSuc: 'N_T_SUC',

    GNSuc: 'GN_SUC',
    GSSuc: 'GS_SUC',
    NLTSuc: 'NLT_SUC',
    NLSuc: 'NL_SUC'
};


export const sortsFilterConstants = {
    SFReq: 'S_F_REQ',
    SFSuc: 'S_F_SUC',
    SFErr: 'S_F_ERR',
    SFClr: 'S_F_CLR',
    SFDelAtt: 'S_F_DEL_ATT',
    SFUptAtt: 'S_F_UPT_ATT',
    SFAddAtt: 'S_F_ADD_ATT'
};
export const sdd_graph = {
    UReq: 'U_REQ',
    USuc: 'U_SUC',
    UErr: 'U_ERR',
    UClr: 'U_CLR',
    UDelAtt: 'U_DEL_ATT',
    UUptAtt: 'U_UPT_ATT',
    UAddAtt: 'U_ADD_ATT',


};

export const sdg_graph_data = {
    GDReq: 'G_D_REQ',
    GDSuc: 'G_D_SUC',
    GDErr: 'G_D_ERR',
    GLReq: 'G_L_REQ',
    GLSuc: 'G_L_SUC',
    GLErr: 'G_L_ERR',
    GOReq: 'G_O_REQ',
    GOSuc: 'G_O_SUC',
    GOErr: 'G_O_ERR',
    GDClr: 'G_D_CLR',
    GDDelAtt: 'G_D_DEL_ATT',
    GDUptAtt: 'G_D_UPT_ATT',
    GDAddAtt: 'G_D_ADD_ATT',


};
export const sdg_filter = {
    FReq: 'F_REQ',
    FSuc: 'F_SUC',
    FErr: 'F_ERR',
    FAIReq: 'F_A_I_REQ',
    FAISuc: 'F_A_I_SUC',
    FAIErr: 'F_A_I_ERR',
    FClr: 'F_CLR',
    FDelAtt: 'F_DEL_ATT',
    FUptAtt: 'F_UPT_ATT',
    FAddAtt: 'F_ADD_ATT'
}
export const sdg_filter_attribute = {
    FAReq: 'F_A_REQ',
    FASuc: 'F_A_SUC',
    FAErr: 'F_A_ERR',
    FOSuc: 'F_O_REQ',
    FOReq: 'F_O_SUC',
    FOErr: 'F_O_ERR',
    SFClr: 'S_F_CLR',
    FADelAtt: 'F_A_DEL_ATT',
    FAUptAtt: 'F_A_UPT_ATT',
    FAAddAtt: 'F_A_ADD_ATT'
}





export const sdggovreport_constant = {
    SGRReq: 'SGR_REQ',
    SGRSuc: 'SGR_SUC',
    SGRErr: 'SGR_ERR',
    SGRClr: 'SGR_CLR',
    SGRDelAtt: 'SGR_DEL_ATT',
    SGRUptAtt: 'SGR_UPT_ATT',
    SGRAddAtt: 'SGR_ADD_ATT'
};


export const projectFileWeb_constant = {
    PFWReq: 'PFW_REQ',
    PFWSuc: 'PFW_SUC',
    PFWErr: 'PFW_ERR',
    PFWClr: 'PFW_CLR',
    PFWDelAtt: 'PFW_DEL_ATT',
    PFWUptAtt: 'PFW_UPT_ATT',
    PFWAddAtt: 'PFW_ADD_ATT'
};




export const trainingCategoriesConstants = {
    TranCReq: 'TRANC_REQ',
    TranCSuc: 'TRANC_SUC',
    TranCErr: 'TRANC_ERR',
    TranCClr: 'TRANC_CLR',
    TranCDelAtt: 'TRANC_DEL_ATT',
    TranCUptAtt: 'TRANC_UPT_ATT',
    TranCAddAtt: 'TRANC_ADD_ATT'
};

export const trainingsConstants = {
    TranReq: 'TRAN_REQ',
    TranSuc: 'TRAN_SUC',
    TranStatAtt: 'TRAN_STATE_ATT',
    TranList: 'TRAN_LIST',
    TranList2: 'TRAN_LIST2',
    TranData: 'TRAN_DATA',
    TranErr: 'TRAN_ERR',
    TranClr: 'TRAN_CLR',
    TranDelAtt: 'TRAN_DEL_ATT',
    TranUptAtt: 'TRAN_UPT_ATT',
    TranAddAtt: 'TRAN_ADD_ATT'
};

export const trainingAdvisoryConstants = {
    TranAdReq: 'TRAN_AD_REQ',
    TranAdSuc: 'TRAN_AD_SUC',
    TranAdList: 'TRAN_AD_LIST',
    TranAdData: 'TRAN_AD_DATA',
    TranAdErr: 'TRAN_AD_ERR',
    TranAdClr: 'TRAN_AD_CLR',
    TranAdDelAtt: 'TRAN_AD_DEL_ATT',
    TranAdUptAtt: 'TRAN_AD_UPT_ATT',
    TranAdAddAtt: 'TRAN_AD_ADD_ATT'
};


export const trainingsEnrollConstants = {
    TranEnrReq: 'TRAN_ENR_REQ',
    TranEnrSuc: 'TRAN_ENR_SUC',
    TranEnrList: 'TRAN_ENR_LIST',
    TranEnrData: 'TRAN_ENR_DATA',
    TranEnrErr: 'TRAN_ENR_ERR',
    TranEnrClr: 'TRAN_ENR_CLR',
    TranEnrDelAtt: 'TRAN_ENR_DEL_ATT',
    TranEnrUptAtt: 'TRAN_ENR_UPT_ATT',
    TranEnrAddAtt: 'TRAN_ENR_ADD_ATT'
};
export const trainingsClassConstants = {
    TranClsReq: 'TRAN_CLS_REQ',
    TranClsSuc: 'TRAN_CLS_SUC',
    TranClsList: 'TRAN_CLS_LIST',
    TranClsData: 'TRAN_CLS_DATA',
    TranClsErr: 'TRAN_CLS_ERR',
    TranClsClr: 'TRAN_CLS_CLR',
    TranClsDelAtt: 'TRAN_CLS_DEL_ATT',
    TranClsUptAtt: 'TRAN_CLS_UPT_ATT',
    TranClsAddAtt: 'TRAN_CLS_ADD_ATT'
};
export const trainingsFileConstants = {
    TranFileReq: 'TRAN_FILE_REQ',
    TranFileSuc: 'TRAN_FILE_SUC',
    TranFileList: 'TRAN_FILE_LIST',
    TranFileData: 'TRAN_FILE_DATA',
    TranFileErr: 'TRAN_FILE_ERR',
    TranFileClr: 'TRAN_FILE_CLR',
    TranFileDelAtt: 'TRAN_FILE_DEL_ATT',
    TranFileUptAtt: 'TRAN_FILE_UPT_ATT',
    TranFileAddAtt: 'TRAN_FILE_ADD_ATT'
};
export const trainingsEnrollPaymentConstants = {
    TranEnrPayReq: 'TRAN_ENR_PAY_REQ',
    TranEnrPaySuc: 'TRAN_ENR_PAY_SUC',
    TranEnrPayData: 'TRAN_ENR_PAY_DATA',
    TranEnrPayErr: 'TRAN_ENR_PAY_ERR',
    TranEnrPayClr: 'TRAN_ENR_PAY_CLR',

};
