
import React from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { reduxStore } from './redux/reducers';
// import Router from '(react-router-dom).Router';
// import {  Router } from "react-router-dom";
import './animate.css'
// import browserHistory from './utils/history'
import './datalist.scss';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";


// import reportWebVitals from './reportWebVitals';



export const queryClient = new QueryClient();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={reduxStore}>
      <App />
    </Provider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();