import React, { lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// import EasyPaisa from "../components/payment_method/EasyPaisa";
// import PaymentType from "../components/payment_method/PaymentType";
// import TrialEnd from "../components/payment_method/TrialEnd";
// import TrialExpired from "../components/payment_method/TrialExpired";
import Topbar from "../components/sdgpanel/topbar/Topbar";
import MobileNav from "../components/sidenav/MobileNav";
import SideNav from "../components/sidenav/SideNav";
// import PageNotFound from "./PageNotFound";

const ProjectPage = lazy(() =>
  import("../components/pages/project/ProjectPage")
);
const ViewProjectPage = lazy(() =>
  import("../components/pages/project/ViewProjectPage")
);
const DashboardPage = lazy(() =>
  import("../components/pages/dashboard/DashboardPage")
);
const DActivitiesPage = lazy(() =>
  import("../components/pages/dashboard/AllActivitiesPage")
);

const EmployeePage = lazy(() =>
  import("../components/pages/employee/EmployeePage")
);
const AllFilesPage = lazy(() =>
  import("../components/pages/allfiles/AllFilesPage")
);

const ViewActivityPage = lazy(() =>
  import("../components/pages/activity/ViewActivityPage")
);
const Jointventures = lazy(() => import("../components/pages/jointventures"));
const ORgSettings = lazy(() => import("../components/sdgpanel/settings"));
const ORgchangepassword = lazy(() =>
  import("../components/sdgpanel/settings/changepassword")
);
const ProfileSettings = lazy(() =>
  import("../components/sdgpanel/settings/ProfileSettings")
);
const Notifications = lazy(() =>
  import("../components/sdgpanel/notifications")
);

const User = ({ org, _id, refreshOrg }) => {
  const location = useLocation();
  let path = location.pathname.split("/")[1];
  // const [EPM, setEPM] = useState(false)
  // const [PTM, setPTM] = useState(false)
  // const [Type, setType] = useState('MA_PAYMENT_METHOD')
  return (
    path === "organization" && (
      <>

        {/* {org&&org.user_type === "org"&&org.isTrial==='T' &&<TrialEnd org={org} setEPM={setPTM}/>}
      {org&&org.user_type === "org"&&org.isTrial==='F' &&<TrialExpired org={org} setEPM={setPTM}/>}
      {PTM&&org&&org.user_type === "org"&&org.isTrial==='F' &&<PaymentType PTM={PTM} org={org} onNext={item => {
                    setType(item === 1 ? 'MA_PAYMENT_METHOD' : 'CC_PAYMENT_METHOD');
                    setEPM(true)
                }}/>}
      {EPM&&org.price&&org&&org.user_type === "org"&&org.isTrial==='F' &&<EasyPaisa Type={Type} price={10} onHandleModal={()=>(setEPM(false))}/>} */}

        <div className={`row mx-0 ${path === "organization" ? " my-3" : ""}`}>
          {path === "organization" && (
            <div className="col-md-3 desktop_view" style={{ width: "21%" }}>
              <SideNav path={location.pathname.split("/")[2]} />
            </div>
          )}

          <div
            className="col-md-9 col-sm-12  overflow-auto userpanel-right full_widthsm"
            style={{ height: "95vh" }}
          >
            {path === "organization" && (
              <>
                <div className="mobile_panel">
                  {" "}
                  <MobileNav path={location.pathname.split("/")[2]} />
                </div>

                <div className="desktop_view">
                  <Topbar path={location.pathname.split("/")[2]} />{" "}
                </div>
              </>
            )}

            <Routes>

              <Route
                exact
                path="/organization/projects"
                element={<ProjectPage />}
              />
              <Route exact path="/organization" element={<DashboardPage org={org} />} />

              <Route
                exact
                path="/organization/dashboard/activities"
                element={<DActivitiesPage />}
              />
              <Route
                exact
                path="/organization/dashboard/view_activity/:_id"
                element={<ViewActivityPage />}
              />

              <Route
                exact
                path="/organization/dashboard/activities/view_activity/:_id"
                element={<ViewActivityPage />}
              />

              <Route
                exact
                path="/organization/employees"
                element={<EmployeePage />}
              />
              <Route
                exact
                path="/organization/all_files"
                element={<AllFilesPage />}
              />
              <Route
                exact
                path="/organization/joint_ventures"
                element={<Jointventures org={org} />}
              />
              <Route
                exact
                path="/organization/projects/view_project/:_id"
                element={<ViewProjectPage />}
              />
              <Route
                exact
                path="/organization/projects/view_activity/:_id"
                element={<ViewActivityPage />}
              />
              <Route
                exact
                path="/organization/joint_ventures/view_activity/:_id"
                element={<ViewActivityPage />}
              />

              <Route
                exact
                path="/organization/joint_ventures/view_joints_project/:_id"
                element={<ViewProjectPage />}
              />

              <Route
                exact
                path="/organization/settings"
                element={<ORgSettings />}
              />
              <Route
                exact
                path="/organization/settings/change_password"
                element={<ORgchangepassword />}
              />
              <Route
                exact
                path="/organization/profile"
                element={<ProfileSettings refreshOrg={refreshOrg} org={org} />}
              />
              <Route
                exact
                path="/organization/notifications"
                element={<Notifications />}
              />

              {/* <Route path="*" element={<Navigate replace to="/organization/dashboard" />} /> */}
              {/* <Route  path="/">
    <Redirect to="/" />
</Route>
<Route  path="*">
    <Redirect to="/dashboard" />
</Route> */}
              {/* <Route path="*" element={<PageNotFound/>} /> */}
            </Routes>
          </div>
        </div>
      </>
    )
  );
};

export default User;
