import React, { lazy, useState } from "react";
import { Routes, Route } from "react-router-dom";

import PageNotFound from "./PageNotFound";
import Form1st from "../components/admindashboard/settings/AddTrainings/Form1st";

const ContactUs = lazy(() => import("../components/landingpage/Contact_Us"));
const DataStatus = lazy(() => import("../components/landingpage/data_status"));
const Registration = lazy(() => import("../components/auth/Registration"));
const Terms = lazy(() => import("../components/pages/landingpagesdg/Terms"));
const Privacy = lazy(() =>
  import("../components/pages/landingpagesdg/Privacy")
);
const YourSelf = lazy(() => import("../components/auth/YourSelf"));
const Login = lazy(() => import("../components/auth/Login"));
const ForgotPassword = lazy(() => import("../components/auth/ForgotPassword"));
const UpdatePassword = lazy(() => import("../components/auth/UpdatePassword"));
const Home = lazy(() => import("../components/landingpage/Home"));
const AboutUs = lazy(() => import("../components/landingpage/about_us"));
const ViewReportPage = lazy(() =>
  import("../components/pages/landingpagesdg/ViewReportPage")
);

const SdgTrackerPage = lazy(() =>
  import("../components/pages/landingpagesdg/SdgTrackerPage")
);
const SdgProgressPage = lazy(() =>
  import("../components/pages/landingpagesdg/SdgProgressPage")
);
const SdgPage = lazy(() =>
  import("../components/pages/landingpagesdg/SdgPage")
);

const SdgOrganizations = lazy(() =>
  import("../components/pages/landingpagesdg/SdgOrganizations")
);
const OrganizationPage = lazy(() =>
  import("../components/pages/landingpagesdg/OrganizationPage")
);
const ViewProjectPage = lazy(() =>
  import("../components/pages/landingpagesdg/ViewProjectPage")
);

const EmailVerification = lazy(() =>
  import("../components/auth/EmailVerification")
);
const EmailMessage = lazy(() => import("../components/auth/Message"));

// const TransactionStatus = lazy(() =>
//   import("../components/payment_method/TransactionStatus")
// );

// const Trainings = lazy(() =>
//   import("../components/pages/landingpagesdg/TrainingsPage")
// );
// const TrainingSingle = lazy(() =>
//   import("../components/pages/landingpagesdg/TrainingSingle")
// );
// const TrainingEnrollment = lazy(() =>
//   import("../components/pages/landingpagesdg/TrainingEnrollment")
// );
// const EnrollPaymentPage = lazy(() =>
//   import("../components/pages/landingpagesdg/EnrollPaymentPage")
// );
// const PaymentResponsePage = lazy(() =>
//   import("../components/pages/landingpagesdg/PaymentResponsePage")
// );

const Advisory = lazy(() =>
  import("../components/pages/landingpagesdg/Advisory")
);

const Landingpage = ({ type, org, _id, setOrg }) => {
  const [Data, setData] = useState(false);
  return (
    <Routes>
      <Route exact path="/404" element={<PageNotFound />} />

      {/* <Route exact path="/transaction-status" element={<TransactionStatus />} /> */}
      <Route exact path="/1sform" element={<Form1st org={org} />} />

      <Route exact path="/" element={<Home org={org} />} />
      <Route exact path="/about_us" element={<AboutUs org={org} />} />
      <Route exact path="/contact_us" element={<ContactUs org={org} />} />
      <Route exact path="/data_status" element={<DataStatus org={org} />} />
      <Route exact path="/message/:email" element={<EmailMessage />} />
      <Route
        exact
        path="/email_verification/:org/:token"
        element={<EmailVerification />}
      />
      <Route
        exact
        path="/update_password/:org/:token"
        element={<UpdatePassword />}
      />
      <Route exact path="/sdg_report/:_id" element={<ViewReportPage />} />

      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/privacy" element={<Privacy />} />

      {/* <Route exact path="/trainings" element={<Trainings />} />
        <Route exact path="/trainings/:id" element={<TrainingSingle />} />
        <Route exact path="/trainings/:id/enroll" element={<TrainingEnrollment />} />
        <Route exact path="/trainings/:id/payment" element={<EnrollPaymentPage />} />
        <Route exact path="/trainings/payment/response" element={<PaymentResponsePage />} /> */}

      <Route exact path="/advisory" element={<Advisory />} />

      <Route exact path="/sdg_tracker" element={<SdgTrackerPage />} />
      <Route exact path="/sdg_tracker/:_id" element={<SdgPage />} />
      <Route
        exact
        path="/sdg_tracker/progress/:_id"
        element={<SdgProgressPage Data={Data} />}
      />

      <Route exact path="/sdg_organizations" element={<SdgOrganizations />} />
      <Route
        exact
        path="/sdg_organizations/:_id"
        element={<OrganizationPage />}
      />
      <Route
        exact
        path="/sdg_organizations/view_project/:_id"
        element={<ViewProjectPage Data={Data} setData={setData} />}
      />

      <Route exact path="/yourSelf" element={<YourSelf />} />
      <Route exact path="/registration" element={<Registration />} />
      <Route exact path="/login" element={<Login setOrg={setOrg} />} />
      <Route exact path="/forgot_password" element={<ForgotPassword />} />
    </Routes>
  );
};

export default Landingpage;
