import { activityOneConstants } from '../constants';
const {
   
    ADReq,
    ADSuc,
    ADErr,
    AFReq,
    AFSuc,
    AFErr,
 
    ARReq,
    ARSuc,
    ARErr,
    AUReq,
    AUSuc,
    AUErr,
    AUClr,
    AUDelAtt,
    AUAddAtt,
  
}= activityOneConstants;

const iSD = {
    isSuc: false, isErr: false, isL: false,data:[]
};
const iSU = {
    isSuc: false, isErr: false, isL: false,list:[]
};

const iSR = {
    isSuc: false, isErr: false, isL: false,data:[]
};

const iSF = {
    isSuc: false, isErr: false, isL: false,list:[]
};



export const ActivityOneD = (state = iSD, action) => {
    switch (action.type) {
        case ADReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case ADSuc: return { ...state, isErr: false, isSuc: true, isL: false ,data:action.payload};
        case ADErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}



export const ActivityOneR = (state = iSR, action) => {


    switch (action.type) {


        case ARSuc: return { ...state, isErr: false, isSuc: true, isL: false ,data:action.payload};
        case ARReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case ARErr: return { ...state, isErr: true, isSuc: false, isL: false };
      
        default: return state;
    }
}


export const ActivityOneU = (state = iSU, action) => {

let listTemp=state.list
    switch (action.type) {
    
        
        case AUReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case AUSuc: return { ...state, isErr: false, isSuc: true, isL: false ,list:action.payload};
        case AUErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case AUClr: return { ...state, isErr: false, isSuc: false, isL: false };

        case AUDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => i._id !== action.payload._id);
            }

            return {
                ...state, list: listTemp, isL: false
            };

       

        case AUAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}


export const ActivityOneF = (state = iSF, action) => {


    switch (action.type) {
         case AFReq: return { ...state, isErr: false, isSuc: false, isL: true,  };
        case AFSuc: return { ...state, isErr: false, isSuc: true, isL: false ,list:action.payload};
        case AFErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}
