import { cityConstants } from '../constants';
const { CTAddAtt, CTClr, CTDelAtt, CTErr, CTReq, CTSuc,CDSuc, CDReq,CDErr,CTUptAtt } = cityConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const CityReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case CTSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case CTReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case CTErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case CDSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };

        case CDReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case CDErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case CTClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case CTDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case CTUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case CTAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
