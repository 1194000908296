import { sdg_filter } from '../constants';
const { FAddAtt, FClr, FDelAtt, FErr, FReq, FSuc,FAIErr, FAIReq, FAISuc, FUptAtt } = sdg_filter;
const iS = {
    isSuc: false, isErr: false, isL: false, list: [], data: []
};

export const FilterReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case FSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case FReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case FErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case FAISuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case FAIReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case FAIErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case FClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case FDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => (i.id).toString() !== (action.payload._id).toString());
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case FUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case FAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
