import { uCConstants } from '../constants';
const { UcAddAtt, UcClr, UcDelAtt, UcErr, UcReq, UcSuc, UcUptAtt } = uCConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const UcReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case UcSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case UcReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case UcErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case UcClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case UcDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case UcUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case UcAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
