import { employeeConstants } from '../constants';
const { EmpAddAtt, EmpClr, EmpDelAtt, EmpErr, EmpReq, EmpSuc, EmpUptAtt } = employeeConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const EmployeeReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case EmpSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case EmpReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case EmpErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case EmpClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case EmpDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case EmpUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case EmpAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
