import { trainingsEnrollConstants } from '../constants';
const {
    TranEnrAddAtt,
    TranEnrClr,
    TranEnrErr,
    TranEnrReq,
    TranEnrSuc,
    TranEnrList,
    TranEnrData,
    TranEnrDelAtt,
    TranEnrUptAtt,
} = trainingsEnrollConstants
const iS = {
    isSuc: false, isErr: false, isL: false, list: [], data: null
};

export const TrainingEnrollment = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case TranEnrSuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case TranEnrList: return { ...state, list: action.payload };
        case TranEnrData: return { ...state, data: action.payload };
        case TranEnrReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TranEnrErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TranEnrClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TranEnrDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => i.id !== action.payload.id);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TranEnrUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if ((i._id) === (action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TranEnrAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
