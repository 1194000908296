import { trainingsClassConstants } from '../constants';
const {
    TranClsAddAtt,
    TranClsClr,
    TranClsErr,
    TranClsReq,
    TranClsSuc,
    TranClsList,
    TranClsData,
    TranClsDelAtt,
    TranClsUptAtt,
} = trainingsClassConstants
const iS = {
    isSuc: false, isErr: false, isL: false, list: [], data: null
};

export const TrainingClass = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case TranClsSuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case TranClsList: return { ...state, list: action.payload };
        case TranClsData: return { ...state, data: action.payload };
        case TranClsReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TranClsErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TranClsClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TranClsDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => i.id !== action.payload.id);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TranClsUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if ((i.id) === (action.payload.id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TranClsAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
