import React from 'react'
import './Loder.css'
import SDGICON from './components/assets/SDG round logo.svg'
const Loader = ({notfi}) => {
  return (
    <div className=' text-center loooder '  style={{textAlign: "center",position:`${notfi&&"absolute"}`,left:`${notfi&&"50%"}`}} >
        <img id="loading" src={SDGICON}  alt="loader"/>
      
    </div>
  )
}

export default Loader