import { trainingsFileConstants } from '../constants';
const {
    TranFileAddAtt,
    TranFileClr,
    TranFileErr,
    TranFileReq,
    TranFileSuc,
    TranFileList,
    TranFileData,
    TranFileDelAtt,
    TranFileUptAtt,
} = trainingsFileConstants
const iS = {
    isSuc: false, isErr: false, isL: false, list: [], data: null
};

export const TrainingFile = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case TranFileSuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case TranFileList: return { ...state, list: action.payload };
        case TranFileData: return { ...state, data: action.payload };
        case TranFileReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TranFileErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TranFileClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TranFileDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => i.id !== action.payload.id);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TranFileUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if ((i.id) === (action.payload.id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TranFileAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
