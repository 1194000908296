import React, { useState, useEffect } from 'react'
import SDGIcon from '../assets/sdglogo.svg'

import DashboardIcon from '../assets/Side nav dashboard.svg'
import EmployeesIcon from '../assets/Side nav employees.svg'
import JointIcon from '../assets/Side Nav joint vneture.svg'
import FilesIcon from '../assets/Sode nav all files.svg'
import Logout from '../../assets/admin/logout.svg'
// import {fetchNotification} from '../../redux/actions/notificationActions'
import { connect } from 'react-redux'
import ProjectsIcon from '../assets/Side nav projects.svg'
import { Link } from 'react-router-dom';

import './sidenav.css'
import NotificationIcon from '../assets/Top nav notification.svg'
import Setting from '../assets/Top nav settings.svg'
import Userdami from '../assets/topavatar.svg'
import { AiOutlineHome } from 'react-icons/ai'
import Offcanvas from 'react-bootstrap/Offcanvas';

import { HiOutlineUser } from 'react-icons/hi'
import { getProfile, getAllSearchList } from '../../redux/actions/profileActions'
import { logOut } from '../../redux/actions/organizationActions'
const MobileNav = ({ logOut, getProfile, getAllSearchList, pId, goalId, JA, DB, org, data, path }) => {
  const [query, setSearchFun] = useState(''), [IPClos, setIPClos] = useState(false), [Project, pathroject] = useState([]), [files, setfiles] = useState([]), [prof, setprof] = useState('');
  const [MM, setMM] = useState(false)
  useEffect(() => {
    getProfile()
  }, [getProfile])

  useEffect(() => {
    getAllSearchList()
  }, [getAllSearchList])

  useEffect(() => {
    if (org && org.profile) { setprof(org.profile) }
  }, [org])


  useEffect(() => {
    if (data && data.project) { pathroject(data.project) }
    if (data && data.files) { setfiles(data.files) }
  }, [data])
  let con = []

  con = con.concat(files, Project)
  let finder = con.filter(post => {
    if (query === '') {
      return null;
    } else if (post.name.toLowerCase().includes(query.toLowerCase())) {
      return post;
    }
    return null
  })


  // let pt = window.location.pathname
  // let date = new Date()
  // const month = date.toLocaleString('default', { month: 'short' });
  // const y = date.getFullYear()
  // const nth = function (d) {
  //     const dString = String(d);
  //     const last = +dString.slice(-2);
  //     if (last > 3 && last < 21) return `${last}th`;
  //     switch (last % 10) {
  //         case 1: return `${last}st`;
  //         case 2: return `${last}nd`;
  //         case 3: return `${last}rd`;
  //         default: return `${last}th`;
  //     }
  // }
  return (

    <>

      <div className='d-flex justify-content-between align-items-center py-2'>
        <div className='d-flex text-dark   TeamableTop p-0'>
          <img src={SDGIcon} alt="" style={{ width: "170px" }} />


        </div>
        <div className='d-flex'>
          <div className='my-auto'>
            <Link to="/organization/settings">
              <img src={Setting} className="mx-2" style={{ width: "25px", }} alt="setting" />
            </Link>
            <Link to="/organization/notifications">
              <img src={NotificationIcon} className="mx-2" style={{ width: "22px" }} alt="notification" />

            </Link>
          </div>
          <div>
            <button className="btn bg-transparent border-0 " type="button" onClick={() => setMM(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list text-end" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="  mt-1  d-flex" style={{ width: "100%" }}>
        <div className="input-group mb-3 d-flex ">
          <input type="text" className="form-control searchbar border-0 " style={{
            backgroundColor: "#F6F6F6", borderRadius: `${finder.length > 0 && IPClos ? "20px 20px 0px 0px" : "20px"}`,
            borderBottomRightRadius: "20px", height: "38px", padding: " 0px 0px 0px 46px"
          }} placeholder="Search " value={query} onChange={(event) => {
            setIPClos(true)
            setSearchFun(event.target.value)
          }} aria-label="Username" aria-describedby="basic-addon1" />

        </div>
        {finder.length > 0 && IPClos && <div className='shadow-sm' style={{ position: "absolute", overflow: "auto", zIndex: 2, width: "91.5%", maxHeight: "67%", top: "117px", }}>
          <div className='list-group border-0'>
            {finder && finder.map(e => <>
              <Link to={
                e.proj_type === 'joints' ? `/organization/joint_ventures/view_joints_project/${e._id}`
                  : e.proj_type === 'individual' ? `/organization/projects/view_project/${e._id}`
                    : e.activity && e.project && e.project.proj_type === 'joints' ? `/organization/joint_ventures/view_joints_activity/${e.activity._id}`
                      : e.activity && e.project && e.project.proj_type === 'individual' ? `/organization/projects/view_activity/${e.activity._id}`
                        : e.project && !e.activity && e.project.proj_type === 'joints' ? `/organization/joint_ventures/view_joints_project/${e.project._id}`
                          : e.project && !e.activity && e.project.proj_type === 'individual' ? `/organization/projects/view_project/${e.project._id}`
                            : ""} className="list-group-item border-0 border-bottom list-group-item-action" onClick={() => {
                              setSearchFun('')
                              setIPClos(false)
                            }}>
                <div className='d-flex '>
                  <img src={`${e.proj_type === 'joints' ? JointIcon : e.proj_type === 'individual' ? ProjectsIcon : e.type ? FilesIcon : ""}`} alt="" style={{ width: `${e.type ? "12px" : "16px"}` }} />
                  <p className='p-0 my-0 mx-2  '>
                    <p className='p-0 m-0' style={{ fontSize: "15px" }}>{e.name}</p>
                    <p className='p-0 m-0' style={{ fontSize: "11px", lineHeight: "5px", color: "gray" }}>
                      {e.proj_type === 'joints' && 'Joints Venture'}

                      {e.proj_type === 'individual' && 'Project'}
                      <span className='text-capitalize'>{e.project && e.project.name}</span>
                    </p>
                  </p>
                </div>
              </Link>
            </>)}
          </div>

        </div>}
      </div>
      <Offcanvas show={MM} onHide={() => setMM(false)} placement="end" style={{
        background: "rgb(246, 246, 246)",
        width: "90%",
        borderRadius: " 13px 0px 0px 13px"
      }}>
        <Offcanvas.Header >
          <Offcanvas.Title></Offcanvas.Title>
          <button type="button" className="btn " onClick={() => setMM(false)}>

            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16" style={{ color: "#00b4a2" }}>
              <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='text-center'>
            <img src={prof && prof.logo ? prof.logo : Userdami} className="mx-2 border-dark rounded-circle  " style={{ width: "67px", height: "64px", cursor: "pointer" }} alt="user" />
            <p className='p-0 m-0'>{prof && <>{prof.f_name} {prof.l_name}</>}</p>
          </div>
          <div className=" sidenavul border-0  " style={{ position: "relative", top: "10px", padding: "61px 0px" }}>

            <ul>
              <li>
                <Link className={`d-flex text-dark text-decoration-none ${!path || path === "dashboard" || DB ? "setActivebg" : ""}`} to="/organization" onClick={() => setMM(false)}><img src={DashboardIcon} alt="dashboard" style={{ width: "18px" }} /><p className='FiloSubMenu ' style={{ marginLeft: "3px" }}>Dashboard</p> </Link>
              </li>

              <li>
                <Link className={`d-flex text-dark text-decoration-none ${path === "projects" ? "setActivebg" : ""}`} to="/organization/projects" onClick={() => setMM(false)}><img src={ProjectsIcon} alt="projects" style={{ width: "15px" }} /><p className='FiloSubMenu mx-2'>Projects</p> </Link>
              </li>
              <li>
                <Link className={`d-flex text-dark text-decoration-none ${path === "employees" && "setActivebg"}`} to="/organization/employees" onClick={() => setMM(false)}><img src={EmployeesIcon} alt="" style={{ width: "16px" }} /><p className='FiloSubMenu mx-2'>Employees</p> </Link>
              </li>
              <li>
                <Link className={`d-flex text-dark text-decoration-none ${path === "joint_ventures" ? "setActivebg" : ""}`} to="/organization/joint_ventures" onClick={() => setMM(false)}><img src={JointIcon} alt="" style={{ width: "18px" }} /><p className='FiloSubMenu'>Joint Ventures</p> </Link>
              </li>
              <li>
                <Link className={`d-flex text-dark text-decoration-none ${path === "all_files" && "setActivebg"}`} to="/organization/all_files" onClick={() => setMM(false)}><img src={FilesIcon} alt="" style={{ width: "13px" }} /><p className='FiloSubMenu mx-2'>All Files</p> </Link>
              </li>

              <li><Link to="/organization/profile" className={`d-flex text-dark text-decoration-none ${path === "profile" && "setActivebg"}`} onClick={() => setMM(false)} style={{ fontSize: "14px" }}><HiOutlineUser style={{ fontSize: "20px" }} /><p className='FiloSubMenu '>View Profile</p></Link></li>
              <li><Link to="/" className="d-flex text-dark text-decoration-none" style={{ fontSize: "14px" }} onClick={() => setMM(false)}><AiOutlineHome className="" style={{ fontSize: "17px" }} /><p className='FiloSubMenu '>Landing Page</p></Link></li>

              <li style={{ position: "absolute", bottom: "-57px", left: "26px" }}>
                <button type='button' className={`d-flex text-dark  btn `} onClick={() => {
                  logOut()
                  localStorage.removeItem('token')
                  window.location.href = "/login"
                }}><img src={Logout} alt="" style={{ width: "22px" }} /><p className='FiloSubMenu mx-1'>Logout</p> </button>
              </li>
            </ul>

          </div>
        </Offcanvas.Body>
      </Offcanvas>






    </>

  )
}
const mapStateToProps = (state) => {
  return {
    data: state.Notification.Sdata,

    org: state.profile.data
  }
}
export default connect(mapStateToProps, { logOut, getProfile, getAllSearchList })(MobileNav)
