import { sdg_graph_data } from '../constants';
const { GDAddAtt, GDClr, GDDelAtt, GDErr, GDReq, GDSuc, GLErr, GLReq, GLSuc,GDUptAtt,GOReq, GOSuc, GOErr, } = sdg_graph_data;
const iS = {
    isSuc: false, isErr: false, isL: false, list: [],data:[]
};
const iSO = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const GDataReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case GDSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case GDReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GDErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GLSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case GLReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GLErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GDClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case GDDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case GDUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case GDAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}

//will get data contributor
export const DataContributorReducer = (state = iSO, action) => {



    switch (action.type) {
        case GOSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case GOReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GOErr: return { ...state, isErr: true, isSuc: false, isL: false };
      
        

        default: return state;
    }
}
