import React, { useState, useEffect } from 'react'
import SDGIcon from '../../assets/sdglogo.svg'
import DashboardIcon from '../../assets/Side nav dashboard.svg'
import JointIcon from '../../assets/Side Nav joint vneture.svg'
import FilesIcon from '../../assets/Sode nav all files.svg'
import ProjectsIcon from '../../assets/Side nav projects.svg'
import ArrowUP from '../../../assets/admin/arrow_up.svg'
import ArrowDown from '../../../assets/admin/arrow_down.svg'
import DonorsIcon from '../../../assets/admin/Donors.svg'
import PrivateIcon from '../../../assets/admin/Private Companies.svg'
import PublicIcon from '../../../assets/admin/Public Sector.svg'
import StackIcon from '../../../assets/admin/stackholders.svg'
import NotificationIcon from '../../../assets/admin/bell.svg'
import Setting from '../../assets/Top nav settings.svg'
import { Link } from 'react-router-dom'

import { fetchNotification } from '../../../redux/actions/notificationActions'
import Userdami from '../../assets/topavatar.svg'
import { connect } from 'react-redux';
import { getAllSearchListForAdmin } from '../../../redux/actions/profileActions';
import { logOut } from '../../../redux/actions/organizationActions'
import Offcanvas from 'react-bootstrap/Offcanvas';

import ViewIcon from '../../../assets/sdg/view_profile.svg'
import LogoutIcon from '../../../assets/sdg/logout.svg'
import './sidenav.css'
import { AiOutlineHome } from 'react-icons/ai'
const MobileNav = ({ Path, goalId, stack_holder, org, PS, pId, notiData, JP, fetchNotification, DB, settings, activity, getAllSearchListForAdmin, data, orgName, PData }) => {
    const [Arrow, setArrow] = useState(false), [IPClos, setIPClos] = useState(false), [query, setSearchFun] = useState(''), [Project, setProject] = useState([]), [files, setfiles] = useState([]), [Org, setOrg] = useState('');
    const [MM, setMM] = useState(false)
    useEffect(() => {
        if (data && data.project) { setProject(data.project) }

        if (data && data.files) { setfiles(data.files) }
    }, [data])
    useEffect(() => {
        if (PData) { setOrg(PData) }
    }, [PData])
    useEffect(() => {
        getAllSearchListForAdmin()
    }, [getAllSearchListForAdmin])
    useEffect(() => {
        fetchNotification()
    }, [fetchNotification])
    let con = []

    con = con.concat(files, Project)
    let finder = con.filter(post => {
        if (query === '') {
            return null;
        } else if (post.name.toLowerCase().includes(query.toLowerCase())) {
            return post;
        }
        return null
    })



    useEffect(() => {
        fetchNotification()
    }, [fetchNotification])


    return (

        <>
            <div className='d-flex justify-content-between align-items-center py-1 px-2' style={{ borderBottom: "1px solid #00b4a2" }} >
                <div className='d-flex text-dark    pb-1'>
                    <img src={SDGIcon} alt="" style={{ width: "180px" }} />

                </div>
                <div className='d-flex'>
                    <div className='mt-2'>
                        <Link to="/superadmin/settings">
                            <img src={Setting} className="mx-2" style={{ width: "25px", }} alt="setting" />
                        </Link>
                        <Link to="/superadmin/notifications">
                            <img src={NotificationIcon} className="mx-2" style={{ width: "25px" }} alt="notification" />

                        </Link>
                    </div>
                    <div>
                        <button className="btn bg-transparent border-0 " type="button" onClick={() => setMM(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-list text-end" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className=" px-3 pt-1  d-flex" style={{ width: "100%" }}>
                <div className="input-group mb-3 d-flex ">
                    <input type="text" className="form-control border-0  searchbar" style={{
                        backgroundColor: "#F6F6F6", borderRadius: `${finder.length > 0 && IPClos ? "20px 20px 0px 0px" : "20px"}`,
                        borderBottomRightRadius: "20px", height: "40px", padding: " 0px 0px 0px 46px"
                    }} placeholder="Search " value={query} onChange={(event) => {
                        setIPClos(true)
                        setSearchFun(event.target.value)
                    }} aria-label="Username" aria-describedby="basic-addon1" />

                </div>
                {finder.length > 0 && IPClos && <div className='shadow-sm' style={{ position: "absolute", overflow: "auto", zIndex: "1", width: "90.5%", maxHeight: "70%", top: "130px", }}>
                    <div className='list-group border-0'>
                        {finder && finder.map(e => <>
                            <Link to={
                                e.proj_type === 'joints' ? `/superadmin/joint_ventures/view_joints_project/${e._id}`
                                    : e.proj_type === 'individual' ? `/superadmin/projects/view_project/${e._id}`
                                        : e.activity && e.project && e.project.proj_type === 'joints' ? `/superadmin/joint_ventures/view_joints_activity/${e.activity._id}`
                                            : e.activity && e.project && e.project.proj_type === 'individual' ? `/superadmin/projects/view_activity/${e.activity._id}`
                                                : e.project && !e.activity && e.project.proj_type === 'joints' ? `/superadmin/joint_ventures/view_joints_project/${e.project._id}`
                                                    : e.project && !e.activity && e.project.proj_type === 'individual' ? `/superadmin/projects/view_project/${e.project._id}`
                                                        : ""} className="list-group-item border-0 border-bottom list-group-item-action" onClick={() => {
                                                            setSearchFun('')
                                                            setIPClos(false)
                                                        }}>
                                <div className='d-flex '>
                                    <img src={`${e.proj_type === 'joints' ? JointIcon : e.proj_type === 'individual' ? ProjectsIcon : e.type ? FilesIcon : ""}`} alt="" style={{ width: `${e.type ? "12px" : "16px"}` }} />
                                    <p className='p-0 my-0 mx-2  '>
                                        <p className='p-0 m-0' style={{ fontSize: "15px" }}>{e.name}</p>
                                        <p className='p-0 m-0' style={{ fontSize: "11px", lineHeight: "5px", color: "gray" }}>
                                            {e.proj_type === 'joints' && 'Joints Venture'}

                                            {e.proj_type === 'individual' && 'Project'}
                                            <p className='m-0 text-capitalize'>{e.project && e.project.name}</p>

                                        </p>
                                    </p>
                                </div>
                            </Link>
                        </>)}
                    </div>

                </div>}
            </div>
            {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Toggle right offcanvas</button> */}
            <Offcanvas show={MM} onHide={() => setMM(false)} placement="end" style={{
                background: "rgb(246, 246, 246)",
                width: "90%",
                borderRadius: " 13px 0px 0px 13px"
            }}>
                <Offcanvas.Header >
                    <Offcanvas.Title></Offcanvas.Title>
                    <button type="button" className="btn " onClick={() => setMM(false)}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16" style={{ color: "#00b4a2" }}>
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                    </button>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-0'>
                    <div className='text-center'>
                        <img src={Org && Org.logo ? Org.logo : Userdami} className="mx-2 border-dark rounded-circle  " style={{ width: "67px", height: "64px", cursor: "pointer" }} alt="user" />
                        <p className='p-0 m-0'>{Org && <>{Org.f_name} {Org.l_name}</>}</p>
                    </div>
                    <div className=" sidenavul p-0 border-0  " style={{ position: "relative", top: "10px", }}>
                        <ul  >
                            <li>
                                <Link className={`d-flex text-dark text-decoration-none ${!Path ? "setActivebg" : DB ? "setActivebg" : ""}`} to="/superadmin" onClick={() => setMM(false)}><img src={DashboardIcon} alt="dashboard" style={{ width: "18px" }} /><p className='FiloSubMenu ' style={{ marginLeft: "3px" }}>Dashboard</p> </Link>
                            </li>

                            <li>




                                <button className="btn drop py-1 border-0  d-flex " style={{ padding: "0px 34px" }} onClick={() => setArrow(Arrow === true ? false : true)} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    <img src={StackIcon} alt="" style={{ width: "18px" }} /><p className='FiloSubMenu mx-1 text-dark d-flex'>Stakeholders<img src={!Arrow ? ArrowDown : ArrowUP} alt="arrowup" style={{ width: "16px", marginLeft: "54px", marginTop: "3px" }} /> </p>
                                </button>

                                <div className={`${Path === "private_companies" || org || PS || Path === "public_sectors" || stack_holder || Path === "donors" ? '' : "collapse"}`} id="collapseExample" onClick={() => setMM(false)}>
                                    <div className="card card-body bg-transparent border-0" style={{ padding: "0px 0px 0px 56px" }}>
                                        <ul>
                                            <li>
                                                <Link className={`d-flex text-secondary text-decoration-none ${Path === "private_companies" || org || stack_holder ? "setActivebg" : ""}`} to="/superadmin/private_companies" onClick={() => setMM(false)}><img src={PrivateIcon} alt="projects" style={{ width: "13px" }} /><p className='p-0 my-0 mx-2'>Private Companies</p> </Link>
                                            </li>
                                            <li>
                                                <Link className={`d-flex text-secondary text-decoration-none  ${Path === "public_sectors" || PS ? "setActivebg" : ""}`} to="/superadmin/public_sectors" onClick={() => setMM(false)}><img src={PublicIcon} alt="" style={{ width: "12px" }} /><p className='p-0 my-0 mx-2'>Public Sectors </p> </Link>
                                            </li>
                                            <li>
                                                <Link className={`d-flex text-secondary text-decoration-none  ${Path === "donors" && "setActivebg"}`} to="/superadmin/donors" onClick={() => setMM(false)}><img src={DonorsIcon} alt="" style={{ width: "16px" }} /><p className='p-0 my-0 mx-2'>Donors </p> </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Link className={`d-flex text-dark text-decoration-none ${Path === "projects" || pId ? "setActivebg" : ""}`} to="/superadmin/projects" onClick={() => setMM(false)}><img src={ProjectsIcon} alt="projects" style={{ width: "15px" }} /><p className='FiloSubMenu mx-2'>Projects</p> </Link>
                            </li>
                            {/* <li>
            <Link className={`d-flex text-dark text-decoration-none ${Path === "/employees" && "setActivebg"}`} to="/admin/geographic"><img src={EmployeesIcon} alt="" style={{ width: "16px" }} /><p className='FiloSubMenu mx-2'>Geographic </p> </Link>
          </li> */}
                            <li>
                                <Link className={`d-flex text-dark text-decoration-none ${Path === "joint_ventures" || JP ? "setActivebg" : ""}`} to="/superadmin/joint_ventures" onClick={() => setMM(false)}><img src={JointIcon} alt="" style={{ width: "18px" }} /><p className='FiloSubMenu'>Joint Ventures</p> </Link>
                            </li>
                            <li>
                                <Link className={`d-flex text-dark text-decoration-none ${Path === "files" && "setActivebg"}`} to="/superadmin/files" onClick={() => setMM(false)}><img src={FilesIcon} alt="" style={{ width: "13px" }} /><p className='FiloSubMenu mx-2'>All Files</p> </Link>
                            </li>
                            <li>
                                <Link className={`d-flex position-relative text-dark text-decoration-none ${Path === "notifications" && "setActivebg"}`} to="/superadmin/notifications" onClick={() => setMM(false)}>
                                    {notiData > 0 && <p className='p-1 m-0 bg-danger rounded' style={{ position: "absolute", left: "38px" }}></p>}
                                    <img src={NotificationIcon} alt="" style={{ width: "16px" }} /><p className='FiloSubMenu mx-1'>Notifications</p> </Link>
                            </li>
                            <li>
                                <Link className={`d-flex text-dark text-decoration-none ${Path === "/superadmin/profile" ? "setActivebg" : DB ? "setActivebg" : ""}`} to="/superadmin/profile" onClick={() => setMM(false)}><img src={ViewIcon} alt="ViewIcon" style={{ width: "18px" }} /><p className='FiloSubMenu ' style={{ marginLeft: "3px" }}>View Profile</p> </Link>
                            </li>
                            <li><Link to="/" className="d-flex text-dark text-decoration-none" style={{ fontSize: "14px" }} onClick={() => setMM(false)}><AiOutlineHome className="" style={{ fontSize: "17px" }} /><p className='FiloSubMenu '>Landing Page</p></Link></li>

                        </ul>
                    </div>
                    <div className='position-absolute bottom-0 ' style={{ width: "313px", margin: "0px 0px 16px 25px" }}>
                        <div className={`d-flex text-dark  btn `} onClick={() => {
                            logOut()
                            localStorage.removeItem('token')
                            window.location.href = "/login"
                        }}><img src={LogoutIcon} alt="" style={{ width: "22px" }} /><p className='FiloSubMenu mx-1'>Logout</p> </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            {/* <div className='text-dark  py-4  ' style={{ position: "fixed", borderRadius: "10px", height: " 95%", width: "20%" }}>
      <div className='d-flex TeamableTop'>
        <div> <img src={SDGIcon} alt="" style={{ width: "40px" }} />
        </div>
        <div className='' style={{ marginLeft: "14px" }}>
          <h5>SDG Tracker</h5>
          <p>Super Admin</p>
          <Toast />
        </div>
      </div>


    </div> */}

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        notiData: state.NotificationCount.count,
        data: state.Notification.Sdata,
        PData: state.profile.list
    }
}
export default connect(mapStateToProps, { logOut, fetchNotification, getAllSearchListForAdmin })(MobileNav)
