import { profileConstants, modalConstants } from "../constants";
const { GPErr, GPReq, GPSuc,PErr,PReq,PSuc,SideNav,SideType,GPClr } = profileConstants;
//CUErr,GDErr, GDReq, GDSuc, CUSuc, GPReqS,  GDESuc
const { MShow, MHide, NTMHide, NTMShow } = modalConstants;

const iS = {
    isSuc: false, isErr: false, isL: false, list: [],data:[]
};


export const profile = (state = iS, action) => {
    switch (action.type) {
        case GPSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case GPReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GPErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GPClr: return { ...state, isErr: false, isSuc: false, isL: false };

        case PSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case PReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case PErr: return { ...state, isErr: true, isSuc: false, isL: false };
        default: return state;
    }
};

export const sidenavReducer = (state = { count: -1, type: '' }, action) => {
    switch (action.type) {
        case SideNav: return { ...state, count: action.payload };
        case SideType: return { ...state, type: action.payload };
        default: return state;
    }
};

export const modalReducer = (state = { isSH: false, isNSH: true }, action) => {
    switch (action.type) {
        case MShow: return { ...state, isSH: true, data: action.payload };
        case MHide: return { ...state, isSH: false };
        case NTMShow: return { ...state, isNSH: true, ndata: action.payload };
        case NTMHide: return { ...state, isNSH: false };
        default: return state;
    }
};