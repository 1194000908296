
import { projectConstants,projectFileWeb_constant } from '../constants';
const { PROJAddAtt, PROJClr, PROJDelAtt, PROJErr, PROJReq, PROJSuc, PROJUptAtt,GPROJSuc ,
    PWErr, PWReq, PWSuc,GPErr, GPReq, GPSuc, } = projectConstants;


    const  {
        PFWReq,
        PFWSuc,
        PFWErr,
     
    }=projectFileWeb_constant

    const PiS = {
        isSuc: false, isErr: false, isL: false, list: []
    };
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const ProjectReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case PROJSuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case PROJReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case PROJErr: return { ...state, isErr: true, isSuc: false, isL: false };

        case PWSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case PWReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case PWErr: return { ...state, isErr: true, isSuc: false, isL: false };

        case GPSuc: return { ...state, isErr: false, isSuc: true, isL: false, data1: action.payload };
        case GPReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GPErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GPROJSuc: return { ...state, list: action.payload };
        case PROJClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case PROJDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case PROJUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case PROJAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }
            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}



export const ProjectFilesWebReducer = (state = PiS, action) => {

 

    switch (action.type) {
        case PFWSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case PFWReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case PFWErr: return { ...state, isErr: true, isSuc: false, isL: false };


        default: return state;
    }
}
