import { trainingsConstants } from '../constants';
const {
    TranAddAtt,
    TranClr,
    TranStatAtt,
    TranErr,
    TranReq,
    TranSuc,
    TranList,
    TranList2,
    TranData,
    TranDelAtt,
    TranUptAtt,
} = trainingsConstants
const iS = {
    isSuc: false, isErr: false, isL: false, list: [], list2: [], data: null
};

export const TrainingReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case TranSuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case TranList: return { ...state, list: action.payload };
        case TranList2: return { ...state, list2: action.payload };

        case TranData: return { ...state, data: action.payload };
        case TranReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TranErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TranClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TranDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => i.id !== action.payload.id);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TranStatAtt:
            if (listTemp && listTemp.length > 0)

                listTemp = listTemp.map(i => {
                    if ((i.id) === (action.payload.id)) i = { ...i, is_active: action.payload.is_active };
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };

        case TranUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if ((i._id) === (action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TranAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
