import React, { lazy } from 'react';
import { Routes, Route,useLocation } from "react-router-dom";



const CompanyPage = lazy(() => import('../components/donors/companies'));
const GoalPage = lazy(() => import('../components/donors/goals'));
const AreaPage = lazy(() => import('../components/donors/area'));
const Dashboard = lazy(() => import('../components/donors/dashboard'));
const Settings = lazy(() => import('../components/donors/settings'));
const Changepassword = lazy(() => import('../components/donors/settings/changepassword'));
const ProfileSettings = lazy(() => import('../components/donors/settings/ProfileSettings'));
const DonationPage = lazy(() => import('../components/donors/donation'));
const NotificationsPage = lazy(() => import('../components/donors/notifications'));




const Donor= ({ type, org, _id }) => {
    const location = useLocation();
    let path = location.pathname.split("/")[1];
    // let subPath = location.pathname.split("/")[2];
    return (path==='donor'&&
        <Routes>
            
        <Route exact path="/donor" element={<Dashboard org={org} />} />
            
        <Route exact path="/donor/donation"  element={<DonationPage org={org} />}/> 
        <Route exact path="/donor/notifications" element={<NotificationsPage org={org} />} /> 
        
        
        <Route exact path="/donor/companies" element={<CompanyPage org={org} />}/> 
        <Route exact path="/donor/area"  element={ <AreaPage org={org} />}/>
        <Route exact path="/donor/goal"  element={<GoalPage org={org} />}/> 
        <Route exact path="/donor/settings" element={<Settings org={org} />}/> 
        <Route exact path="/donor/settings/change_password" element={<Changepassword org={org} />} /> 
        <Route exact path="/donor/profile"  element={ <ProfileSettings org={org} />}/>
        
        </Routes>
    )
}


export default Donor