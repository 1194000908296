import { provinceConstants } from '../constants';
const { ProvAddAtt, ProvClr, ProvDelAtt, ProvErr, ProvReq, ProvSuc, ProvUptAtt } = provinceConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const ProvinceReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case ProvSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case ProvReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case ProvErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case ProvClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case ProvDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case ProvUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case ProvAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
