import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ModalHide, ModalProcess } from '../../redux/actions/profileActions';
import './style.css';

const Toast = ({ data, isSH, ModalHide, ModalProcess }) => {
    useEffect(() => {
        ModalProcess()
    }, [ModalProcess])
    return (
        <div className={`mtoast mshowing ${data && data.title === 'merror' && 'mtoastError'}`} style={{ display: `${isSH && data ? 'block' : 'none'}`, zIndex: '9999' }}>
            <div className="mtoast-body  ">
                <p className='fs-6'>{data && data.text}</p>
            </div>
            {data && data.progress &&

                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: `${data.progress}%` }}>{data.progress}%</div>
                </div>
            }

        </div>
    )
}

const mapStateToProps = state => {
    return {
        isSH: state.Modal.isSH,
        data: state.Modal.data
    }
};

export default connect(mapStateToProps, { ModalHide, ModalProcess })(Toast);


