import { goalConstants } from '../constants';
const { GAddAtt, GClr, GDelAtt, GErr, GReq, GSuc, GUptAtt,GCSuc,GOErr, GOReq, GOSuc, GLErr, GLReq, GLSuc,
    GTOErr, GTOReq, GTOSuc,GPErr, GPReq, GPSuc,  } = goalConstants;
  
//GNSuc
const iS = {
    isSuc: false, isErr: false, isL: false, list: [],new:''
};

export const GoalReducer = (state = iS, action) => {

    let listTemp = state.list;
    let listAdd = state.new;

    switch (action.type) {
        case GSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case GCSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };

        case GReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case GOSuc: return { ...state, isErr: false, isSuc: true, isL: false, data2: action.payload };
        case GOReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GOErr: return { ...state, isErr: true, isSuc: false, isL: false };

        case GPSuc: return { ...state, isErr: false, isSuc: true, isL: false, data5: action.payload };
        case GPReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GPErr: return { ...state, isErr: true, isSuc: false, isL: false };

        case GLSuc: return { ...state, isErr: false, isSuc: true, isL: false, data3: action.payload };
        case GLReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GLErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GTOSuc: return { ...state, isErr: false, isSuc: true, isL: false, data4: action.payload };
        case GTOReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GTOErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case GUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case GAddAtt:
        
                listAdd = action.payload;
         

            return {
                ...state, new: listAdd, isL: false
            };

        default: return state;
    }
}
