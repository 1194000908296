import React, { useEffect } from 'react';

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {ModalProcessNT} from '../../redux/actions/profileActions'
import {connect} from 'react-redux'
function MainToast({ModalProcessNT,data,isNSH}) {
   

  useEffect(()=>{
    ModalProcessNT()
  },[ModalProcessNT])


  return (

     data&& <ToastContainer className="p-3" position={'bottom-start'} style={{zIndex:1}}>

        <Toast className="d-flex align-items-center text-white" bg={"danger"}  show={isNSH} delay={3000}   animation={true} style={{minHeight:"70px"}} >
     
          <Toast.Body>{data&&data.title}</Toast.Body>
        </Toast>
        </ToastContainer>
 
  );
}
const mapStateToProps=(state)=>{
    return{
        isNSH:state.Modal.isNSH,
        data:state.Modal.ndata

    }
}
export default connect(mapStateToProps,{ModalProcessNT})(MainToast);

