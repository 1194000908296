
import { jointVenturesConstants } from '../constants';
const { JVAddAtt, JVClr, JVDelAtt, JVErr, JVReq, JVSuc, JVUptAtt,GJVSuc,
    JWErr, JWReq, JWSuc, } = jointVenturesConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const JointVenturesReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case JVSuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case JVReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case JVErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case JWSuc: return { ...state, isErr: false, isSuc: true, isL: false,data: action.payload };
        case JWReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case JWErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case GJVSuc: return { ...state, list: action.payload };
        case JVClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case JVDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case JVUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case JVAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }
            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
