import { sdggovreport_constant } from '../constants';
const {  SGRReq,
    SGRSuc,
    SGRErr,
    SGRClr,
    SGRDelAtt,
    SGRUptAtt,
    SGRAddAtt, } = sdggovreport_constant;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const GovReportReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case SGRSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case SGRReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case SGRErr: return { ...state, isErr: true, isSuc: false, isL: false };

        case SGRClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case SGRDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i =>i._id!== action.payload._id);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case SGRUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (i._id === action.payload._id) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case SGRAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
