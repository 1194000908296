import axios from "axios";

//export const baseUrl = "http://localhost:89";

//export const baseUrl = 'http://192.168.18.148:89'
// export const webUrl = 'http://192.168.18.148:3000'
export const baseUrl = "https://sdg.cwaret.com";
//http://192.168.18.144:81
// "https://sdg.cwaret.com"
export default axios.create({
  baseURL: `${baseUrl}/api/sdg`,
});
//export const baseUrlF = 'http://192.168.18.148:3000'

export const baseUrlF = "https://database.sdgsacademy.pk";
