import React, { useState, useRef } from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  DatePicker,
  TimePicker,
  Button,
  Upload,
  Space,
  Divider,
  message,
} from "antd";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined, UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
let index = 0;
const Form1st = () => {
  const onFinish = (values) => {
    console.log("Form values:", values);
    // Handle form submission here
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [items, setItems] = useState(["jack", "lucy"]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    if (!name) {
      return;
    }
    setItems([...items, name || `New item ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  const [fileList, setFileList] = useState([]);
  const handleFileChange = (info, index) => {
    const newFileList = [...fileList];
    newFileList[index] = info.fileList.slice(-1)[0];
    setFileList(newFileList);
  };

  const handleRemoveFile = (index) => {
    const newFileList = [...fileList];
    newFileList[index] = null;
    setFileList(newFileList);
  };

  const uploadProps = (index) => ({
    fileList: fileList[index] ? [fileList[index]] : [],
    onChange: (info) => handleFileChange(info, index),
    beforeUpload: () => false, // Prevent actual file upload
  });
  const [form] = Form.useForm();

  return (
    <div className="overflow-auto h-100vh" style={{ height: "100vh" }}>
      <Form onFinish={onFinish} form={form} layout="vertical" labelAlign="left">
        <Form.Item
          label="Training Category"
          name="trainingCategory"
          rules={[
            { required: true, message: "Please select a training category" },
          ]}
        >
          <Select
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: "8px 0",
                  }}
                />
                <Space
                  style={{
                    padding: "0 8px 4px",
                  }}
                >
                  <Input
                    required
                    placeholder="Please enter item"
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Add item
                  </Button>
                </Space>
              </>
            )}
            placeholder="Select a category"
            options={items.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </Form.Item>

        <Form.Item
          label="Training Name"
          name="trainingName"
          rules={[{ required: true, message: "Please enter a training name" }]}
        >
          <Input placeholder="Enter training name" />
        </Form.Item>
        {({ getFieldValue }) => {
          console.log("er", getFieldValue("trainingImage"));
        }}
        <Form.Item
          label="Training Image"
          name="trainingImage"
          rules={[
            { required: true, message: "Please upload a training image" },
          ]}
          getValueFromEvent={normFile}
        >
          <Upload
            listType="picture-card"
            multiple={false}
            maxCount={1}
            beforeUpload={() => false}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item
          className="col-4"
          label="Relevant SDG"
          name="relevantSDG"
          maxTagCount={"responsive"}
          rules={[
            {
              required: true,
              message: "Please select at least one relevant SDG",
            },
          ]}
        >
          <Select mode="multiple" placeholder="Select relevant SDGs">
            <Option value="sdg1">SDG 1</Option>
            <Option value="sdg2">SDG 2</Option>
            <Option value="sdg3">SDG 3</Option>
            {/* Add more SDG options */}
          </Select>
        </Form.Item>

        <Form.Item
          label="Training Overview"
          name="trainingOverview"
          rules={[
            { required: true, message: "Please enter a training overview" },
          ]}
        >
          <TextArea placeholder="Enter training overview" />
        </Form.Item>

        <Form.Item
          label="Training Duration"
          name="trainingDuration"
          rules={[
            { required: true, message: "Please enter training duration" },
          ]}
        >
          <Input placeholder="Enter training duration" />
        </Form.Item>

        <Form.Item
          label="Date Range"
          name="dateRange"
          rules={[{ required: true, message: "Please select a date range" }]}
        >
          <DatePicker.RangePicker />
        </Form.Item>

        <Form.Item
          label="Training Fee"
          name="trainingFee"
          rules={[{ required: true, message: "Please enter training fee" }]}
        >
          <Input placeholder="Enter training fee" />
        </Form.Item>

        <Form.Item
          label="Training Days"
          name="trainingDays"
          rules={[
            {
              required: true,
              message: "Please select at least one training day",
            },
          ]}
        >
          <Checkbox.Group>
            <Checkbox value="monday">Monday</Checkbox>
            <Checkbox value="tuesday">Tuesday</Checkbox>
            <Checkbox value="wednesday">Wednesday</Checkbox>
            <Checkbox value="thursday">Thursday</Checkbox>
            <Checkbox value="friday">Friday</Checkbox>
            <Checkbox value="saturday">Saturday</Checkbox>
            <Checkbox value="sunday">Sunday</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          label="Class Duration"
          name="classDuration"
          rules={[{ required: true, message: "Please select class duration" }]}
        >
          <TimePicker.RangePicker />
        </Form.Item>
        <Form.List
          name="instructors"
          initialValue={[
            {
              name: "",
              qualification: "",
              experience: "",
              image: "",
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <h3>Instructor {index + 1}</h3>

                  <Form.Item
                    label="Instructor Name"
                    name={[field.name, "name"]}
                    key={[field.key, "name"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter instructor name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter instructor name" />
                  </Form.Item>

                  <Form.Item
                    label="Instructor Qualification"
                    name={[field.name, "qualification"]}
                    key={[field.key, "qualification"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter instructor qualification",
                      },
                    ]}
                  >
                    <Input placeholder="Enter instructor qualification" />
                  </Form.Item>

                  <Form.Item
                    label="Instructor Experience"
                    name={[field.name, "experience"]}
                    key={[field.key, "experience"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter instructor experience",
                      },
                    ]}
                  >
                    <Input placeholder="Enter instructor experience" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "experience"]}
                    label="Experience"
                    key={[field.key, "experience"]}
                    rules={[
                      {
                        required: true,
                        message: "Experience is required",
                      },
                    ]}
                  >
                    <Input placeholder="Enter experience" />
                  </Form.Item>

                  <Button type="danger" onClick={() => remove(field.name)}>
                    Remove Instructor
                  </Button>
                </div>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Add Instructor
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          label="Venue"
          name="venue"
          rules={[
            { required: true, message: "Please enter the training venue" },
          ]}
        >
          <TextArea placeholder="Enter training venue" />
        </Form.Item>

        <Form.Item label="Target Audience" name="targetAudience">
          <TextArea placeholder="Enter target audience details" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form1st;
