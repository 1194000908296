import { tehsilConstants } from '../constants';
const { TehAddAtt, TehClr, TehDelAtt, TehErr, TehReq, TehSuc, TehUptAtt } = tehsilConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const TehsilReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case TehSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case TehReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TehErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TehClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TehDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TehUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TehAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
