import { sdg_filter_attribute } from '../constants';
const { FAAddAtt, FAClr, FADelAtt, FAErr, FAReq, FASuc, FAUptAtt,FOSuc ,FOReq,FOErr} = sdg_filter_attribute;
const iS = {
    isSuc: false, isErr: false, isL: false, list: [],data:[]
};

export const FAReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case FASuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case FAReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case FAErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case FOSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case FOReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case FOErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case FAClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case FADelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case FAUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case FAAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
