import React, { useState, useEffect } from 'react'
import SDGIcon from '../../assets/sdglogo.svg'
import DashboardIcon from '../../assets/Side nav dashboard.svg'
import JointIcon from '../../assets/Side Nav joint vneture.svg'
import FilesIcon from '../../assets/Sode nav all files.svg'
import ProjectsIcon from '../../assets/Side nav projects.svg'
import ArrowUP from '../../../assets/admin/arrow_up.svg'
import ArrowDown from '../../../assets/admin/arrow_down.svg'
import DonorsIcon from '../../../assets/admin/Donors.svg'
import PrivateIcon from '../../../assets/admin/Private Companies.svg'
import PublicIcon from '../../../assets/admin/Public Sector.svg'
import StackIcon from '../../../assets/admin/stackholders.svg'
import NotificationIcon from '../../../assets/admin/bell.svg'

import { Link } from 'react-router-dom'

import { fetchNotification } from '../../../redux/actions/notificationActions'
import { connect } from 'react-redux'
import './sidenav.css'
const SideNav = ({ Path, goalId, stack_holder, org, PS, pId, notiData, JP, fetchNotification, DB }) => {
  const [Arrow, setArrow] = useState(false);


  useEffect(() => {
    fetchNotification()
  }, [fetchNotification])


  return (

    <div className='text-dark  pb-4  ' style={{ position: "fixed", borderRadius: "10px", height: " 95%", width: "20%" }}>
      <div className='d-flex TeamableTop'>
        <img src={SDGIcon} alt="" style={{ width: "195px" }} />

      </div>
      <div className=" sidenavul border-0  " style={{ position: "relative", top: "10px", padding: "61px 0px" }}>
        <ul>
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${!Path ? "setActivebg" : DB ? "setActivebg" : ""}`} to="/superadmin"><img src={DashboardIcon} alt="dashboard" style={{ width: "18px" }} /><p className='FiloSubMenu ' style={{ marginLeft: "3px" }}>Dashboard</p> </Link>
          </li>

          <li>




            <button className="btn drop py-1 border-0  d-flex " style={{ padding: "0px 36px" }} onClick={() => setArrow(Arrow === true ? false : true)} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              <img src={StackIcon} alt="" style={{ width: "18px" }} /><p className='FiloSubMenu mx-1 text-dark d-flex'>Stakeholders<img src={!Arrow ? ArrowDown : ArrowUP} alt="arrowup" className=' ms-1' style={{width:"13px"}} /> </p>
            </button>

            <div className={`${Path === "private_companies" || org || PS || Path === "public_sectors" || stack_holder || Path === "donors" ? '' : "collapse"}`} id="collapseExample">
              <div className="card card-body bg-transparent border-0" style={{ padding: "0px 0px 0px 56px" }}>
                <ul>
                  <li>
                    <Link className={`d-flex text-secondary text-decoration-none ${Path === "private_companies" || org || stack_holder ? "setActivebg" : ""}`} to="/superadmin/private_companies"><img src={PrivateIcon} alt="projects" style={{ width: "13px" }} /><p className='p-0 my-0 mx-2'>Private Companies</p> </Link>
                  </li>
                  <li>
                    <Link className={`d-flex text-secondary text-decoration-none  ${Path === "public_sectors" || PS ? "setActivebg" : ""}`} to="/superadmin/public_sectors"><img src={PublicIcon} alt="" style={{ width: "12px" }} /><p className='p-0 my-0 mx-2'>Public Sectors </p> </Link>
                  </li>
                  <li>
                    <Link className={`d-flex text-secondary text-decoration-none  ${Path === "donors" && "setActivebg"}`} to="/superadmin/donors"><img src={DonorsIcon} alt="" style={{ width: "16px" }} /><p className='p-0 my-0 mx-2'>Donors </p> </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${Path === "projects" || pId ? "setActivebg" : ""}`} to="/superadmin/projects"><img src={ProjectsIcon} alt="projects" style={{ width: "15px" }} /><p className='FiloSubMenu mx-2'>Projects</p> </Link>
          </li>
          {/* <li>
            <Link className={`d-flex text-dark text-decoration-none ${Path === "/employees" && "setActivebg"}`} to="/admin/geographic"><img src={EmployeesIcon} alt="" style={{ width: "16px" }} /><p className='FiloSubMenu mx-2'>Geographic </p> </Link>
          </li> */}
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${Path === "joint_ventures" || JP ? "setActivebg" : ""}`} to="/superadmin/joint_ventures"><img src={JointIcon} alt="" style={{ width: "18px" }} /><p className='FiloSubMenu'>Joint Ventures</p> </Link>
          </li>
          <li>
            <Link className={`d-flex text-dark text-decoration-none ${Path === "files" && "setActivebg"}`} to="/superadmin/files"><img src={FilesIcon} alt="" style={{ width: "13px" }} /><p className='FiloSubMenu mx-2'>All Files</p> </Link>
          </li>
          <li>
            <Link className={`d-flex position-relative text-dark text-decoration-none ${Path === "notifications" && "setActivebg"}`} to="/superadmin/notifications">
              {notiData > 0 && <p className='p-1 m-0 bg-danger rounded' style={{ position: "absolute", left: "38px" }}></p>}
              <img src={NotificationIcon} alt="" style={{ width: "16px" }} /><p className='FiloSubMenu mx-1'>Notifications</p> </Link>
          </li>

          <li>
            <Link className={`d-flex text-dark text-decoration-none ${Path === "trainings" && "setActivebg"}`} to="/superadmin/trainings"><img src={FilesIcon} alt="" style={{ width: "13px" }} /><p className='FiloSubMenu mx-2'>Trainings</p> </Link>
          </li>
        </ul>
      </div>

    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    notiData: state.NotificationCount.count
  }
}
export default connect(mapStateToProps, { fetchNotification })(SideNav)