import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { GoalReducer } from "./goalReducer";
import { TargetReducer } from "./targetReducer";
import { IndicatorReducer } from "./indicatorReducer";
import { sidenavReducer, modalReducer, profile } from "./profileReducer";
import { ProjectFilesWebReducer, ProjectReducer } from "./projectReducer";

import { ProvinceReducer } from "./provienceReducer";
import { DistrictReducer } from "./districtReducer";
import { TehsilReducer } from "./tehsilReducer";
import { UcReducer } from "./ucReducer";
import { EmployeeReducer } from "./employeeReducer";
import { UserReducer } from "./userReducer";
import { FileReducer } from "./fileReducer";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import { composeWithDevTools } from 'redux-devtools-extension';
import { projActReducer } from "./projActivityReducer";
import { JointVenturesReducer } from "./jointVenturesReducer";
import { OrganizationReducer } from "./organizationReducer";
import { CityReducer } from "./cityReducer";
import { NotifReducer, countReducer } from "./notificationReducer";
import { DonorReducer } from "./donorReducer";
import { GUnitReducer } from "./graphReducer";
import { FilterReducer } from "./filterReducer";
import { FAReducer } from "./filterAttributeReducer";
import { DataContributorReducer, GDataReducer } from "./graphDataReducer";
import { ProjectOneA, ProjectOneD, ProjectOneR, ProjectOneF, ProjectOneU, ProjectOneG, ProjectOneI, ProjectOneO } from "./projectOneReducer";
import { ActivityOneD, ActivityOneF, ActivityOneR, ActivityOneU } from "./activityOneReducer";
import { GovReportReducer } from "./govreportReducer";
import { TrainingCategoriesReducer } from "./trainingCategoriesReducer"
import { TrainingReducer } from "./trainingReducer";
import { TrainingAdvisoryReducer } from "./trainingAdvisoryReducer";
import { TrainingEnrollment } from "./trainingEnrollment";
import { TrainingEnrollPayment } from "./trainingEnrollPayment";
import { TrainingClass } from "./trainingClass";
import { TrainingFile } from "./trainingFiles";
const CombinedReducers = combineReducers({
  Login: loginReducer,
  GoalReducer: GoalReducer,
  TargetReducer: TargetReducer,
  IndicatorReducer: IndicatorReducer,
  Modal: modalReducer,
  sidenav: sidenavReducer,
  ProvinceReducer: ProvinceReducer,
  DistrictReducer: DistrictReducer,
  TehsilReducer: TehsilReducer,
  UcReducer: UcReducer,
  GUnit: GUnitReducer,
  Filter: FilterReducer,
  FilterAtt: FAReducer,
  GraphData: GDataReducer,
  UserReducer: UserReducer,
  EmployeeReducer: EmployeeReducer,
  File: FileReducer,
  ProjectReducer: ProjectReducer,
  ProjAct: projActReducer,
  JVentures: JointVenturesReducer,
  Org: OrganizationReducer,
  City: CityReducer,
  Notification: NotifReducer,
  NotificationCount: countReducer,
  profile: profile,
  donor: DonorReducer,

  projectOneD: ProjectOneD,
  projectOneA: ProjectOneA,
  projectOneG: ProjectOneG,
  projectOneR: ProjectOneR,
  projectOneU: ProjectOneU,
  projectOneF: ProjectOneF,
  projectOneI: ProjectOneI,
  projectOneO: ProjectOneO,

  activityOneD: ActivityOneD,
  activityOneR: ActivityOneR,
  activityOneU: ActivityOneU,
  activityOneF: ActivityOneF,
  govReport: GovReportReducer,
  DataContributors: DataContributorReducer,
  ProjectFilesWeb: ProjectFilesWebReducer,
  TrainCat: TrainingCategoriesReducer,
  training: TrainingReducer,
  trainingAdvisory: TrainingAdvisoryReducer,
  trainingEnroll: TrainingEnrollment,
  trainingEnrollPay: TrainingEnrollPayment,
  trainingClass: TrainingClass,
  trainingFile: TrainingFile

});

export const reduxStore = createStore(CombinedReducers, applyMiddleware(thunk));

export default CombinedReducers;
