
import { indicatorConstants } from '../constants';
const { IAddAtt,GISuc, IClr, IDelAtt, IErr, IReq, ISuc, IUptAtt,IWGErr, IWGReq, IWGSuc, } = indicatorConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: [],new:[]
};

export const IndicatorReducer = (state = iS, action) => {
    let listTemp = state.list;
    let listAdd = state.new;

    switch (action.type) {
        case ISuc: return { ...state, isErr: false, isSuc: true, isL: false,};
        case IReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GISuc: return { ...state, list: action.payload };
        case IErr: return { ...state, isErr: true, isSuc: false, isL: false };

        case IWGSuc: return { ...state, isErr: false, isSuc: true, isL: false,data: action.payload};
        case IWGReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case IWGErr: return { ...state, isErr: true, isSuc: false, isL: false };

        case IClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case IDelAtt:
            if (listAdd && listAdd.length > 0) {
                
                listAdd = listAdd.filter(i => i._id !== action.payload._id);
            }

            return {
                ...state, new: listAdd, isL: false
            };

        case IUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case IAddAtt:
            if (listAdd && listAdd.length > 0)
            listAdd = listAdd.concat([action.payload]);
            else if (listAdd) {
                listAdd = [].concat([action.payload]);
            }
           

            return {
                ...state, new: listAdd, isL: false
            };

        default: return state;
    }
}
