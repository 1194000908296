import React, { lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "../components/admindashboard/topbar/Topbar";
import SideNav from "../components/admindashboard/sidenav/SideNav";
import MobileNav from "../components/admindashboard/sidenav/MobileNav";
const FilterPage = lazy(() =>
  import("../components/admindashboard/settings/filter")
);

const Admindashboard = lazy(() =>
  import("../components/admindashboard/dashboard")
);
const SProjectsPage = lazy(() =>
  import("../components/admindashboard/pages/ProjectsPage")
);
const SJointsProjectsPage = lazy(() =>
  import("../components/admindashboard/pages/JointsProjectsPage")
);
const SAllFilePage = lazy(() =>
  import("../components/admindashboard/pages/AllFilesPage")
);
const SPrivateCompaniesPage = lazy(() =>
  import("../components/admindashboard/pages/PrivateCompaniesPage")
);
const SPublicSectorsPage = lazy(() =>
  import("../components/admindashboard/pages/PublicSectorsPage")
);
const SDonorsPage = lazy(() =>
  import("../components/admindashboard/pages/DonorsPage")
);
const SStackHoldersViewPage = lazy(() =>
  import("../components/admindashboard/StackHoldersview/PrivateViewPage")
);
const SPublicViewPage = lazy(() =>
  import("../components/admindashboard/StackHoldersview/PublicViewPage")
);
const SNotificationsPage = lazy(() =>
  import("../components/admindashboard/pages/NotificationsPage")
);

const SDRecentActivityPage = lazy(() =>
  import("../components/admindashboard/pages/RecentActivityPage")
);
const AdminLogin = lazy(() => import("../components/auth/AdminPage"));
const Settings = lazy(() => import("../components/admindashboard/settings"));
const SDGSettings = lazy(() =>
  import("../components/admindashboard/settings/sdg")
);
const GEOSettings = lazy(() =>
  import("../components/admindashboard/settings/geographic")
);
const PassSettings = lazy(() =>
  import("../components/admindashboard/settings/changepassword")
);
const NAdminSettings = lazy(() =>
  import("../components/admindashboard/settings/newadmin")
);
const AddTrainings = lazy(() =>
  import("../components/admindashboard/settings/AddTrainings")
);
const EditTrainings = lazy(() =>
  import("../components/admindashboard/settings/EditTrainings/EditTraining")
);
const AddAdvisoryMember = lazy(() =>
  import("../components/admindashboard/settings/AddAdvisoryMember")
);
const Profile = lazy(() =>
  import("../components/admindashboard/settings/ProfileSettings")
);
const SDGGraph = lazy(() =>
  import("../components/admindashboard/settings/sdggraph")
);

const ViewProjectPage = lazy(() =>
  import("../components/pages/project/ViewProjectPage")
);
const ViewActivityPage = lazy(() =>
  import("../components/pages/activity/ViewActivityPage")
);
const AddGovReportData = lazy(() =>
  import("../components/admindashboard/govreport/AddData")
);
const TrainingsListPage = lazy(() =>
  import("../components/admindashboard/pages/trainings/TrainingsListPage")
);
const TrainingViewPage = lazy(() =>
  import("../components/admindashboard/pages/trainings/TrainingViewPage")
);
const TrainingEnrollmentsListPage = lazy(() =>
  import("../components/admindashboard/pages/trainings/EnrollmentsPage")
);
const TrainingCandidatePage = lazy(() =>
  import("../components/admindashboard/pages/trainings/TrainingCandidatePage")
);
const TrainingClassesListPage = lazy(() =>
  import("../components/admindashboard/pages/trainings/ClassesPage")
);
const TrainingAddClassesPage = lazy(() =>
  import("../components/admindashboard/pages/trainings/AddClassesPage")
);
const TrainingUpdateClassesPage = lazy(() =>
  import("../components/admindashboard/pages/trainings/UpdateClassPage")
);
const TrainingMaterialsPage = lazy(() =>
  import("../components/admindashboard/pages/trainings/MaterialsPage")
);

const Superadmin = ({ type, org, _id }) => {
  const location = useLocation();
  let path = location.pathname.split("/")[1];
  let subPath = location.pathname.split("/")[2];
  // let key= location.key

  return (
    path === "superadmin" && (
      <>
        <div className="row mx-1 my-3">
          <div
            className="col-lg-3   p-0 m-0 desktop_view"
            style={{ width: "20.1%" }}
          >
            <SideNav Path={subPath} />
          </div>
          <div
            className="col-lg-9   p-0 m-0 full_widthsm"
            style={{ width: "79.9%" }}
          >
            <div className="mobile_panel">
              {" "}
              <MobileNav Path={subPath} />
            </div>
            <div className="desktop_view ">
              <Topbar Path={subPath} />{" "}
            </div>
            <>
              <Routes>
                <Route
                  exact
                  path="/superadmin/govreport"
                  element={<AddGovReportData />}
                />

                <Route exact path="/admin" element={<AdminLogin />} />

                <Route
                  exact
                  path="/superadmin"
                  element={<Admindashboard org={org} />}
                />
                <Route
                  exact
                  path="/superadmin/projects"
                  element={<SProjectsPage />}
                />
                <Route
                  exact
                  path="/superadmin/joint_ventures"
                  element={<SJointsProjectsPage />}
                />
                <Route
                  exact
                  path="/superadmin/files"
                  element={<SAllFilePage />}
                />
                <Route
                  exact
                  path="/superadmin/private_companies"
                  element={<SPrivateCompaniesPage />}
                />
                <Route
                  exact
                  path="/superadmin/public_sectors"
                  element={<SPublicSectorsPage />}
                />
                <Route
                  exact
                  path="/superadmin/donors"
                  element={<SDonorsPage />}
                />
                <Route
                  exact
                  path="/superadmin/private_companies/:org/"
                  element={<SStackHoldersViewPage />}
                />

                <Route
                  exact
                  path="/superadmin/public_sector/:org"
                  element={<SPublicViewPage />}
                />
                <Route
                  exact
                  path="/superadmin/notifications"
                  element={<SNotificationsPage />}
                />
                <Route
                  exact
                  path="/superadmin/projects/view_project/:_id"
                  element={<ViewProjectPage superadmin={true} />}
                />
                <Route
                  exact
                  path="/superadmin/projects/view_activity/:_id"
                  element={<ViewActivityPage superadmin={true} />}
                />
                <Route
                  exact
                  path="/superadmin/joint_ventures/view_joints_project/:_id"
                  element={<ViewProjectPage superadmin={true} />}
                />
                <Route
                  exact
                  path="/superadmin/joint_ventures/view_activity/:_id"
                  element={<ViewActivityPage superadmin={true} />}
                />
                <Route
                  exact
                  path="/superadmin/view_activity/:_id"
                  element={<ViewActivityPage superadmin={true} />}
                />
                <Route
                  exact
                  path="/superadmin/recent_activities/view_activity/:_id"
                  element={<ViewActivityPage superadmin={true} />}
                />
                <Route
                  exact
                  path="/superadmin/recent_activities"
                  element={<SDRecentActivityPage />}
                />
                <Route
                  exact
                  path="/superadmin/settings"
                  element={<Settings />}
                />
                <Route
                  exact
                  path="/superadmin/settings/sdg"
                  element={<SDGSettings />}
                />
                <Route
                  exact
                  path="/superadmin/settings/geographic"
                  element={<GEOSettings />}
                />
                <Route
                  exact
                  path="/superadmin/settings/change_password"
                  element={<PassSettings />}
                />
                <Route
                  exact
                  path="/superadmin/settings/new_admin"
                  element={<NAdminSettings />}
                />
                <Route
                  exact
                  path="/superadmin/profile"
                  element={<Profile org={org} />}
                />
                <Route
                  exact
                  path="/superadmin/settings/add_sdg_graph"
                  element={<SDGGraph />}
                />
                <Route
                  exact
                  path="/superadmin/settings/filter_page"
                  element={<FilterPage />}
                />
                <Route
                  exact
                  path="/superadmin/settings/add_trainings"
                  element={<AddTrainings />}
                />
                <Route
                  exact
                  path="/superadmin/settings/edit_trainings/:id"
                  element={<EditTrainings />}
                />
                <Route
                  exact
                  path="/superadmin/settings/add_advisory"
                  element={<AddAdvisoryMember />}
                />

                <Route
                  exact
                  path="/superadmin/trainings"
                  element={<TrainingsListPage />}
                />
                <Route
                  exact
                  path="/superadmin/trainings/:id/overview"
                  element={<TrainingViewPage />}
                />

                <Route
                  exact
                  path="/superadmin/trainings/:id/enrollments"
                  element={<TrainingEnrollmentsListPage />}
                />
                <Route
                  exact
                  path="/superadmin/trainings/:id/enrollments/:candidateId"
                  element={<TrainingCandidatePage />}
                />

                <Route
                  exact
                  path="/superadmin/trainings/:id/classes"
                  element={<TrainingClassesListPage />}
                />
                <Route
                  exact
                  path="/superadmin/trainings/:id/classes/add"
                  element={<TrainingAddClassesPage />}
                />
                <Route
                  exact
                  path="/superadmin/trainings/:id/classes/update/:classId"
                  element={<TrainingUpdateClassesPage />}
                />
                <Route
                  exact
                  path="/superadmin/trainings/:id/materials"
                  element={<TrainingMaterialsPage />}
                />
              </Routes>
            </>
          </div>
        </div>
      </>
    )
  );
};
export default Superadmin;
