import { donorConstants } from '../constants';
const { DAddAtt, DClr, DDelAtt, DErr, DReq, DSuc, DUptAtt } = donorConstants;
const iS = {
    isSuc: false, isErr: false, isL: false, list: []
};

export const DonorReducer = (state = iS, action) => {

    let listTemp = state.list;

    switch (action.type) {
        case DSuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case DReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case DErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case DClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case DDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case DUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case DAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat([action.payload]);
            else if (listTemp) {
                listTemp = [].concat([action.payload]);
            }

            return {
                ...state, list: listTemp, isL: false
            };

        default: return state;
    }
}
