import React, { lazy, useState, useEffect } from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { getAllByType } from "../redux/actions/docsActions";
const ProjectPage = lazy(() => import("../components/doc/Pages/ProjectPage"));
const VenturesPage = lazy(() => import("../components/doc/Pages/VenturesPage"));
const ActivitiesPage = lazy(() =>
  import("../components/doc/Pages/ActivitiesPage")
);
const ParticipantsPage = lazy(() =>
  import("../components/doc/Pages/ParticipantsPage")
);
const FilesPage = lazy(() => import("../components/doc/Pages/FilesPage"));
const SettingPage = lazy(() => import("../components/doc/Pages/SettingPage"));
const ProfilePage = lazy(() => import("../components/doc/Pages/ProfilePage"));
const HowDonatePage = lazy(() =>
  import("../components/doc/Pages/HowDonatePage")
);
const DonarSettingPage = lazy(() =>
  import("../components/doc/Pages/DonarSettingPage")
);
const DonateHistoryPage = lazy(() =>
  import("../components/doc/Pages/DonateHistoryPage")
);
const DonarProfilePage = lazy(() =>
  import("../components/doc/Pages/DonarProfilePage")
);
const SignUpPage = lazy(() => import("../components/doc/Pages/SignUpPage"));
const SdgStatusPage = lazy(() =>
  import("../components/doc/Pages/SdgStatusPage")
);
const WelcomePage = lazy(() => import("../components/doc/Pages/WelcomePage"));
const SideBar = lazy(() =>
  import("../components/doc/components/Sidebar/SideBar")
);
const TopBar = lazy(() => import("../components/doc/components/topbar/TopBar"));
const EmployeePage = lazy(() => import("../components/doc/Pages/EmployeePage"));

const Doc = () => {

  const [Data, setData] = useState([]);
  const [OpenM, setOpenM] = useState(false);
  useEffect(() => {
    async function fetch() {
      let res = await getAllByType();
      setData(res);
    }
    fetch();
  }, []);



  const location = useLocation();
  let path = location.pathname.split("/")[1];
  return (
    path === "doc" && (
      <>
        <>
          <div className="row mx-0 overflow-auto" style={{ height: "94vh" }}>
            <TopBar setOpenM={setOpenM} />

            <div className="col-md-2 ">
              <SideBar OpenM={OpenM} setOpenM={setOpenM} />
            </div>

            <div className="col-md-8 ">
              
              <Routes>
                <Route path="/doc" element={<WelcomePage Data={Data} />} />
                <Route
                  path="/doc/projects"
                  element={
                    <ProjectPage
                      Data={Data}
                    />
                  }
                />
                <Route
                  path="/doc/ventures"
                  element={
                    <VenturesPage
                      Data={
                        Data
                      }
                    />
                  }
                />
                <Route
                  path="/doc/activities"
                  element={<ActivitiesPage Data={Data} />}
                />
                <Route
                  path="/doc/participants"
                  element={<ParticipantsPage Data={Data} />}
                />
                <Route
                  path="/doc/employees"
                  element={<EmployeePage Data={Data} />}
                />
                <Route path="/doc/files" element={<FilesPage Data={Data} />} />
                <Route
                  path="/doc/settings"
                  element={<SettingPage Data={Data} />}
                />
                <Route
                  path="/doc/profile"
                  element={<ProfilePage Data={Data} />}
                />
                <Route
                  path="/doc/howdonate"
                  element={<HowDonatePage Data={Data} />}
                />
                <Route
                  path="/doc/donatehistory"
                  element={<DonateHistoryPage Data={Data} />}
                />
                <Route
                  path="/doc/donarsettings"
                  element={<DonarSettingPage Data={Data} />}
                />
                <Route
                  path="/doc/donarprofile"
                  element={<DonarProfilePage Data={Data} />}
                />
                <Route
                  path="/doc/signup"
                  element={<SignUpPage Data={Data} />}
                />
                <Route
                  path="/doc/sdgstatus"
                  element={<SdgStatusPage Data={Data} />}
                />
              </Routes>
            </div>
          </div>
        </>
      </>
    )
  );
};

export default Doc;
