import { trainingsEnrollPaymentConstants } from '../constants';
const {
    TranEnrPayClr,
    TranEnrPayErr,
    TranEnrPayReq,
    TranEnrPaySuc,
    TranEnrPayData,
} = trainingsEnrollPaymentConstants
const iS = {
    isSuc: false, isErr: false, isL: false, list: [], data: null
};

export const TrainingEnrollPayment = (state = iS, action) => {


    switch (action.type) {
        case TranEnrPaySuc: return { ...state, isErr: false, isSuc: true, isL: false, };
        case TranEnrPayData: return { ...state, data: action.payload };
        case TranEnrPayReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TranEnrPayErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TranEnrPayClr: return { ...state, isErr: false, isSuc: false, isL: false };


        default: return state;
    }
}
